@import "../../variables.scss";

#CustomerReviews {
  display: none;
}

@media only screen and (min-width: 992px) {
  #CustomerReviews {
    display: block;
    width: 100%;
    height: 261px;
    margin-top: 15px;
  }

  #CustomerReviews > .crs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    background-color: #ffffff;
    border: 5px solid #defaff;
    border-radius: 58px;
    width: 871px;
    height: 261px;
  }
}

@media only screen and (min-width: 1024px) {
  #CustomerReviews {
    height: 269px;
    margin-top: 15px;
  }

  #CustomerReviews > .crs-container {
    border: 5px solid #defaff;
    border-radius: 60px;
    width: 899px;
    height: 269px;
  }
}

@media only screen and (min-width: 1200px) {
  #CustomerReviews {
    height: 315px;
    margin-top: 18px;
  }

  #CustomerReviews > .crs-container {
    border: 6px solid #defaff;
    border-radius: 70px;
    width: 1054px;
    height: 315px;
  }
}

@media only screen and (min-width: 1366px) {
  #CustomerReviews {
    height: 270px;
    margin-top: 37px;
  }

  #CustomerReviews > .crs-container {
    border: 5px solid #defaff;
    border-radius: 43px;
    width: 1005px;
    height: 270px;
  }
}

@media only screen and (min-width: 1440px) {
  #CustomerReviews {
    height: 285px;
    margin-top: 39px;
  }

  #CustomerReviews > .crs-container {
    border: 5px solid #defaff;
    border-radius: 45px;
    width: 1060px;
    height: 285px;
  }
}

@media only screen and (min-width: 1536px) {
  #CustomerReviews {
    height: 304px;
    margin-top: 42px;
  }

  #CustomerReviews > .crs-container {
    border: 6px solid #defaff;
    border-radius: 48px;
    width: 1130px;
    height: 304px;
  }
}

@media only screen and (min-width: 1600px) {
  #CustomerReviews {
    height: 317px;
    margin-top: 43px;
  }

  #CustomerReviews > .crs-container {
    border: 6px solid #defaff;
    border-radius: 50px;
    width: 1178px;
    height: 317px;
  }
}

@media only screen and (min-width: 1800px) {
  #CustomerReviews {
    height: 356px;
    margin-top: 49px;
  }

  #CustomerReviews > .crs-container {
    border: 7px solid #defaff;
    border-radius: 56px;
    width: 1325px;
    height: 356px;
  }
}

@media only screen and (min-width: 1920px) {
  #CustomerReviews {
    height: 380px;
    margin-top: 52px;
  }

  #CustomerReviews > .crs-container {
    border: 7px solid #defaff;
    border-radius: 60px;
    width: 1413px;
    height: 380px;
  }
}

@media only screen and (min-width: 2120px) {
  #CustomerReviews {
    height: 420px;
    margin-top: 57px;
  }

  #CustomerReviews > .crs-container {
    border: 8px solid #defaff;
    border-radius: 66px;
    width: 1560px;
    height: 420px;
  }
}

@media only screen and (min-width: 2320px) {
  #CustomerReviews {
    height: 459px;
    margin-top: 63px;
  }

  #CustomerReviews > .crs-container {
    border: 8px solid #defaff;
    border-radius: 72px;
    width: 1707px;
    height: 459px;
  }
}

@media only screen and (min-width: 2520px) {
  #CustomerReviews {
    height: 499px;
    margin-top: 68px;
  }

  #CustomerReviews > .crs-container {
    border: 9px solid #defaff;
    border-radius: 79px;
    width: 1855px;
    height: 499px;
  }
}

@media only screen and (min-width: 2720px) {
  #CustomerReviews {
    height: 538px;
    margin-top: 74px;
  }

  #CustomerReviews > .crs-container {
    border: 10px solid #defaff;
    border-radius: 85px;
    width: 2002px;
    height: 538px;
  }
}

@media only screen and (min-width: 2920px) {
  #CustomerReviews {
    height: 578px;
    margin-top: 79px;
  }

  #CustomerReviews > .crs-container {
    border: 11px solid #defaff;
    border-radius: 91px;
    width: 2149px;
    height: 578px;
  }
}

@media only screen and (min-width: 3120px) {
  #CustomerReviews {
    height: 618px;
    margin-top: 84px;
  }

  #CustomerReviews > .crs-container {
    border: 11px solid #defaff;
    border-radius: 98px;
    width: 2296px;
    height: 618px;
  }
}

@media only screen and (min-width: 3320px) {
  #CustomerReviews {
    height: 657px;
    margin-top: 90px;
  }

  #CustomerReviews > .crs-container {
    border: 12px solid #defaff;
    border-radius: 104px;
    width: 2443px;
    height: 657px;
  }
}

@media only screen and (min-width: 3520px) {
  #CustomerReviews {
    height: 697px;
    margin-top: 95px;
  }

  #CustomerReviews > .crs-container {
    border: 13px solid #defaff;
    border-radius: 110px;
    width: 2590px;
    height: 697px;
  }
}

@media only screen and (min-width: 3720px) {
  #CustomerReviews {
    height: 736px;
    margin-top: 101px;
  }

  #CustomerReviews > .crs-container {
    border: 14px solid #defaff;
    border-radius: 116px;
    width: 2738px;
    height: 736px;
  }
}

@media only screen and (min-width: 3920px) {
  #CustomerReviews {
    height: 776px;
    margin-top: 106px;
  }

  #CustomerReviews > .crs-container {
    border: 14px solid #defaff;
    border-radius: 122px;
    width: 2885px;
    height: 776px;
  }
}
