$colorf5feff: #f5feff;
$colorffea6c: #ffea6c;
$color7d1cc9: #7d1cc9;
$coloref2996: #ef2996;
$color410e7c: #410e7c;
$color69ed82: #69ed82;
$color280c4c: #280c4c;
$colorffeea4: #ffeea4;
$colorb3f1ff: #b3f1ff;
$colorebffeb: #ebffeb;
$colordefaff: #defaff;
