@import "../../variables.scss";

#TopReview {
  display: none;
}

@media only screen and (min-width: 992px) {
  #TopReview {
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url("data:image/svg+xml;charset=US-ASCII, %3Csvg xmlns='http://www.w3.org/2000/svg' width='197.591' height='113.386'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23e5309c%7D.b%7Bfill:%23b20f70%7D.c%7Bfill:%23d81e8d%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M138.897 20.935a56.691 56.691 0 11-79.752-8.232 56.69 56.69 0 0179.752 8.232z'/%3E%3Cpath class='b' d='M147.361 78.218a56.684 56.684 0 10-75.638 30.218c.017.002 31.459.119 75.638-30.218z'/%3E%3Cpath class='c' d='M59.145 12.703a56.692 56.692 0 00-8.229 79.751q1.33 1.635 2.754 3.143c4.441.564 12.192.4 25.187-5.916 13.21-6.424 15.109-8.359 34.55-16.322s22.777-16.666 29.979-22.015a44.545 44.545 0 007.005-6.337 56.712 56.712 0 00-91.246-32.3z'/%3E%3Cpath class='a' d='M59.145 12.703a56.7 56.7 0 00-13.69 71.724c6.755-1.565 50.446-13.311 93.588-63.308-.048-.061-.1-.124-.146-.184a56.692 56.692 0 00-79.752-8.232z'/%3E%3Cpath class='c' d='M38.421 61.573c.008 0 28.352-10.133 50.726-28.409 19.644-16.043 31.659-20.986 37.95-23.15a56.7 56.7 0 00-88.676 51.559z'/%3E%3Cpath class='c' d='M114.672 3.554a56.712 56.712 0 00-73.137 34s26.792-4.582 73.137-34z'/%3E%3Cpath class='b' d='M43.737 32.26c13.25-3.143 32.027-10.626 56.4-32.013a56.724 56.724 0 00-56.4 32.013z'/%3E%3Cpath d='M40.952 73.557s-31.95 18.55-30.458 27.106 55.623-11.266 89.77-28.136 71.393-38.547 80.33-53.1C188.714 6.209 141.3 24.4 141.3 24.4l-2.067-2.8s38.286-17.16 55.085-12.278c14.672 4.264-20.789 34.442-89.957 68.915C51.861 104.404-.726 120.385.008 105.111c.774-16.09 39.941-35.17 39.941-35.17z' fill='%237cdd8a'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 148px 85px;
    background-position: 70px 59px;
  }

  .tr-container {
    width: 468px;
    height: 230px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 5px solid #defaff;
    border-radius: 44px;
    display: flex;
    flex-direction: column;
  }

  .tr-content {
    width: 310px;
    height: 151px;
    margin: auto;
  }

  .tr-smiley {
    display: inline-block;
    width: 94px;
    height: 94px;
    margin-left: 27px;
    background: $coloref2996 0% 0% no-repeat padding-box;
    border-radius: 50%;
    text-align: center;
    line-height: 94px;
    font-size: 47px;
    color: #ffffff;
  }

  .tr-stars > i {
    width: 23px;
    height: 23px;
    background: $colorffea6c 0% 0% no-repeat padding-box;
    border-radius: 50%;
    background-position: 50% 50%;
    text-align: center;
    vertical-align: bottom;
    line-height: 23px;
    font-size: 16px;
    color: #ffffff;
    margin-right: 7px;
  }

  .tr-stars i:before,
  .tr-stars i:after {
    color: #ffffff;
  }

  .tr-rating {
    display: inline-block;
    vertical-align: top;
    margin-top: 11px;
    margin-left: 25px;
  }

  .tr-rating > .tr-who > p {
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
  }

  .tr-rating > .tr-who > span {
    font-size: 15px;
    line-height: 18px;
  }

  .tr-content > p {
    font-size: 26px;
    line-height: 34px;
    color: $coloref2996;
    margin-top: 19px;
    text-align: center;
  }

  .tr-content > p > strong {
    color: $color69ed82;
  }
}

@media only screen and (min-width: 1024px) {
  #TopReview {
    margin-top: 26px;
    background-size: 153px 88px;
    background-position: 72px 61px;
  }

  .tr-container {
    width: 483px;
    height: 237px;
    border: 5px solid #defaff;
    border-radius: 45px;
  }

  .tr-content {
    width: 314px;
    height: 156px;
  }

  .tr-smiley {
    width: 97px;
    height: 97px;
    margin-left: 28px;
    border-radius: 50%;
    line-height: 97px;
    font-size: 49px;
  }

  .tr-stars > i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    margin-right: 7px;
  }

  .tr-rating {
    margin-top: 11px;
    margin-left: 26px;
  }

  .tr-rating > .tr-who > p {
    font-size: 29px;
    line-height: 35px;
  }

  .tr-rating > .tr-who > span {
    font-size: 15px;
    line-height: 19px;
  }

  .tr-content > p {
    font-size: 27px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  #TopReview {
    margin-top: 30px;
    background-size: 179px 103px;
    background-position: 84px 71px;
  }

  .tr-container {
    width: 566px;
    height: 278px;
    border: 6px solid #defaff;
    border-radius: 53px;
  }

  .tr-content {
    width: 368px;
    height: 183px;
  }

  .tr-smiley {
    width: 114px;
    height: 114px;
    margin-left: 33px;
    line-height: 114px;
    font-size: 57px;
  }

  .tr-stars > i {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 19px;
    margin-right: 8px;
  }

  .tr-rating {
    margin-top: 13px;
    margin-left: 30px;
  }

  .tr-rating > .tr-who > p {
    font-size: 34px;
    line-height: 41px;
  }

  .tr-rating > .tr-who > span {
    font-size: 18px;
    line-height: 22px;
  }

  .tr-content > p {
    font-size: 32px;
    line-height: 41px;
    margin-top: 23px;
  }
}

@media only screen and (min-width: 1366px) {
  #TopReview {
    margin-top: 42px;
    background-size: 141px 80px;
    background-position: 193px 80px;
  }

  .tr-container {
    width: 526px;
    height: 206px;
    border: 4px solid #defaff;
    border-radius: 32px;
  }

  .tr-content {
    width: 287px;
    height: 137px;
  }

  .tr-smiley {
    width: 84px;
    height: 84px;
    margin-left: 24px;
    line-height: 84px;
    font-size: 43px;
  }

  .tr-stars > i {
    width: 21px;
    height: 21px;
    line-height: 21px;
    font-size: 17px;
    margin-right: 5px;
  }

  .tr-rating {
    margin-top: 8px;
    margin-left: 18px;
  }

  .tr-rating > .tr-who > p {
    font-size: 27px;
    line-height: 32px;
  }

  .tr-rating > .tr-who > span {
    font-size: 14px;
    line-height: 16px;
  }

  .tr-content > p {
    font-size: 26px;
    line-height: 32px;
    margin-top: 22px;
  }
}

@media only screen and (min-width: 1440px) {
  #TopReview {
    margin-top: 44px;
    background-size: 148px 85px;
    background-position: 203px 84px;
  }

  .tr-container {
    width: 555px;
    height: 218px;
    border: 4px solid #defaff;
    border-radius: 34px;
  }

  .tr-content {
    width: 302px;
    height: 144px;
  }

  .tr-smiley {
    width: 88px;
    height: 88px;
    margin-left: 26px;
    line-height: 88px;
    font-size: 46px;
  }

  .tr-stars > i {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 18px;
    margin-right: 5px;
  }

  .tr-rating {
    margin-top: 8px;
    margin-left: 20px;
  }

  .tr-rating > .tr-who > p {
    font-size: 28px;
    line-height: 34px;
  }

  .tr-rating > .tr-who > span {
    font-size: 14px;
    line-height: 17px;
  }

  .tr-content > p {
    font-size: 27px;
    line-height: 34px;
    margin-top: 23px;
  }
}

@media only screen and (min-width: 1536px) {
  #TopReview {
    margin-top: 47px;
    background-size: 158px 90px;
    background-position: 217px 90px;
  }

  .tr-container {
    width: 592px;
    height: 232px;
    border: 4px solid #defaff;
    border-radius: 36px;
  }

  .tr-content {
    width: 322px;
    height: 154px;
  }

  .tr-smiley {
    width: 94px;
    height: 94px;
    margin-left: 27px;
    line-height: 94px;
    font-size: 49px;
  }

  .tr-stars > i {
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 19px;
    margin-right: 6px;
  }

  .tr-rating {
    margin-top: 9px;
    margin-left: 21px;
  }

  .tr-rating > .tr-who > p {
    font-size: 30px;
    line-height: 36px;
  }

  .tr-rating > .tr-who > span {
    font-size: 15px;
    line-height: 18px;
  }

  .tr-content > p {
    font-size: 29px;
    line-height: 36px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 1600px) {
  #TopReview {
    margin-top: 49px;
    background-size: 165px 94px;
    background-position: 226px 93px;
  }

  .tr-container {
    width: 617px;
    height: 242px;
    border: 4px solid #defaff;
    border-radius: 38px;
  }

  .tr-content {
    width: 336px;
    height: 160px;
  }

  .tr-smiley {
    width: 98px;
    height: 98px;
    margin-left: 28px;
    line-height: 98px;
    font-size: 51px;
  }

  .tr-stars > i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 20px;
    margin-right: 6px;
  }

  .tr-rating {
    margin-top: 9px;
    margin-left: 22px;
  }

  .tr-rating > .tr-who > p {
    font-size: 32px;
    line-height: 38px;
  }

  .tr-rating > .tr-who > span {
    font-size: 16px;
    line-height: 19px;
  }

  .tr-content > p {
    font-size: 30px;
    line-height: 38px;
    margin-top: 26px;
  }
}

@media only screen and (min-width: 1800px) {
  #TopReview {
    margin-top: 55px;
    background-size: 186px 106px;
    background-position: 254px 105px;
  }

  .tr-container {
    width: 694px;
    height: 272px;
    border: 5px solid #defaff;
    border-radius: 42px;
  }

  .tr-content {
    width: 378px;
    height: 180px;
  }

  .tr-smiley {
    width: 111px;
    height: 111px;
    margin-left: 32px;
    line-height: 111px;
    font-size: 57px;
  }

  .tr-stars > i {
    width: 27px;
    height: 27px;
    line-height: 27px;
    font-size: 22px;
    margin-right: 7px;
  }

  .tr-rating {
    margin-top: 10px;
    margin-left: 24px;
  }

  .tr-rating > .tr-who > p {
    font-size: 36px;
    line-height: 42px;
  }

  .tr-rating > .tr-who > span {
    font-size: 18px;
    line-height: 22px;
  }

  .tr-content > p {
    font-size: 34px;
    line-height: 42px;
    margin-top: 29px;
  }
}

@media only screen and (min-width: 1920px) {
  #TopReview {
    margin-top: 59px;
    background-size: 198px 113px;
    background-position: 271px 112px;
  }

  .tr-container {
    width: 740px;
    height: 290px;
    border: 5px solid #defaff;
    border-radius: 45px;
  }

  .tr-content {
    width: 403px;
    height: 192px;
  }

  .tr-smiley {
    width: 118px;
    height: 118px;
    margin-left: 34px;
    line-height: 118px;
    font-size: 61px;
  }

  .tr-stars > i {
    width: 29px;
    height: 29px;
    line-height: 29px;
    font-size: 24px;
    margin-right: 7px;
  }

  .tr-rating {
    margin-top: 11px;
    margin-left: 26px;
  }

  .tr-rating > .tr-who > p {
    font-size: 38px;
    line-height: 45px;
  }

  .tr-rating > .tr-who > span {
    font-size: 19px;
    line-height: 23px;
  }

  .tr-content > p {
    font-size: 36px;
    line-height: 45px;
    margin-top: 31px;
  }
}

@media only screen and (min-width: 2120px) {
  #TopReview {
    margin-top: 65px;
    background-size: 219px 125px;
    background-position: 299px 124px;
  }

  .tr-container {
    width: 817px;
    height: 320px;
    border: 6px solid #defaff;
    border-radius: 50px;
  }

  .tr-content {
    width: 445px;
    height: 212px;
  }

  .tr-smiley {
    width: 130px;
    height: 130px;
    margin-left: 38px;
    line-height: 130px;
    font-size: 67px;
  }

  .tr-stars > i {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 26px;
    margin-right: 8px;
  }

  .tr-rating {
    margin-top: 12px;
    margin-left: 29px;
  }

  .tr-rating > .tr-who > p {
    font-size: 42px;
    line-height: 50px;
  }

  .tr-rating > .tr-who > span {
    font-size: 21px;
    line-height: 25px;
  }

  .tr-content > p {
    font-size: 40px;
    line-height: 50px;
    margin-top: 34px;
  }
}

@media only screen and (min-width: 2320px) {
  #TopReview {
    margin-top: 71px;
    background-size: 239px 137px;
    background-position: 327px 135px;
  }

  .tr-container {
    width: 894px;
    height: 350px;
    border: 6px solid #defaff;
    border-radius: 54px;
  }

  .tr-content {
    width: 487px;
    height: 232px;
  }

  .tr-smiley {
    width: 143px;
    height: 143px;
    margin-left: 41px;
    line-height: 143px;
    font-size: 74px;
  }

  .tr-stars > i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 29px;
    margin-right: 8px;
  }

  .tr-rating {
    margin-top: 13px;
    margin-left: 31px;
  }

  .tr-rating > .tr-who > p {
    font-size: 46px;
    line-height: 54px;
  }

  .tr-rating > .tr-who > span {
    font-size: 23px;
    line-height: 28px;
  }

  .tr-content > p {
    font-size: 44px;
    line-height: 54px;
    margin-top: 37px;
  }
}

@media only screen and (min-width: 2520px) {
  #TopReview {
    margin-top: 77px;
    background-size: 260px 148px;
    background-position: 356px 147px;
  }

  .tr-container {
    width: 971px;
    height: 381px;
    border: 7px solid #defaff;
    border-radius: 59px;
  }

  .tr-content {
    width: 529px;
    height: 252px;
  }

  .tr-smiley {
    width: 155px;
    height: 155px;
    margin-left: 45px;
    line-height: 155px;
    font-size: 80px;
  }

  .tr-stars > i {
    width: 38px;
    height: 38px;
    line-height: 38px;
    font-size: 32px;
    margin-right: 9px;
  }

  .tr-rating {
    margin-top: 14px;
    margin-left: 34px;
  }

  .tr-rating > .tr-who > p {
    font-size: 50px;
    line-height: 59px;
  }

  .tr-rating > .tr-who > span {
    font-size: 25px;
    line-height: 30px;
  }

  .tr-content > p {
    font-size: 47px;
    line-height: 59px;
    margin-top: 41px;
  }
}

@media only screen and (min-width: 2720px) {
  #TopReview {
    margin-top: 84px;
    background-size: 280px 160px;
    background-position: 384px 159px;
  }

  .tr-container {
    width: 1048px;
    height: 411px;
    border: 7px solid #defaff;
    border-radius: 64px;
  }

  .tr-content {
    width: 571px;
    height: 272px;
  }

  .tr-smiley {
    width: 167px;
    height: 167px;
    margin-left: 48px;
    line-height: 167px;
    font-size: 86px;
  }

  .tr-stars > i {
    width: 41px;
    height: 41px;
    line-height: 41px;
    font-size: 34px;
    margin-right: 10px;
  }

  .tr-rating {
    margin-top: 16px;
    margin-left: 37px;
  }

  .tr-rating > .tr-who > p {
    font-size: 54px;
    line-height: 64px;
  }

  .tr-rating > .tr-who > span {
    font-size: 27px;
    line-height: 33px;
  }

  .tr-content > p {
    font-size: 51px;
    line-height: 64px;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 2920px) {
  #TopReview {
    margin-top: 90px;
    background-size: 301px 172px;
    background-position: 412px 170px;
  }

  .tr-container {
    width: 1125px;
    height: 441px;
    border: 8px solid #defaff;
    border-radius: 68px;
  }

  .tr-content {
    width: 613px;
    height: 292px;
  }

  .tr-smiley {
    width: 179px;
    height: 179px;
    margin-left: 52px;
    line-height: 179px;
    font-size: 93px;
  }

  .tr-stars > i {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 36px;
    margin-right: 11px;
  }

  .tr-rating {
    margin-top: 17px;
    margin-left: 40px;
  }

  .tr-rating > .tr-who > p {
    font-size: 58px;
    line-height: 68px;
  }

  .tr-rating > .tr-who > span {
    font-size: 29px;
    line-height: 35px;
  }

  .tr-content > p {
    font-size: 55px;
    line-height: 68px;
    margin-top: 47px;
  }
}

@media only screen and (min-width: 3120px) {
  #TopReview {
    margin-top: 96px;
    background-size: 322px 184px;
    background-position: 440px 182px;
  }

  .tr-container {
    width: 1202px;
    height: 471px;
    border: 8px solid #defaff;
    border-radius: 73px;
  }

  .tr-content {
    width: 655px;
    height: 312px;
  }

  .tr-smiley {
    width: 192px;
    height: 192px;
    margin-left: 55px;
    line-height: 192px;
    font-size: 99px;
  }

  .tr-stars > i {
    width: 47px;
    height: 47px;
    line-height: 47px;
    font-size: 39px;
    margin-right: 11px;
  }

  .tr-rating {
    margin-top: 18px;
    margin-left: 42px;
  }

  .tr-rating > .tr-who > p {
    font-size: 62px;
    line-height: 73px;
  }

  .tr-rating > .tr-who > span {
    font-size: 31px;
    line-height: 37px;
  }

  .tr-content > p {
    font-size: 58px;
    line-height: 73px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 3320px) {
  #TopReview {
    margin-top: 102px;
    background-size: 342px 195px;
    background-position: 469px 194px;
  }

  .tr-container {
    width: 1280px;
    height: 501px;
    border: 9px solid #defaff;
    border-radius: 78px;
  }

  .tr-content {
    width: 697px;
    height: 332px;
  }

  .tr-smiley {
    width: 204px;
    height: 204px;
    margin-left: 59px;
    line-height: 204px;
    font-size: 105px;
  }

  .tr-stars > i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 42px;
    margin-right: 12px;
  }

  .tr-rating {
    margin-top: 19px;
    margin-left: 45px;
  }

  .tr-rating > .tr-who > p {
    font-size: 66px;
    line-height: 78px;
  }

  .tr-rating > .tr-who > span {
    font-size: 33px;
    line-height: 40px;
  }

  .tr-content > p {
    font-size: 62px;
    line-height: 78px;
    margin-top: 54px;
  }
}

@media only screen and (min-width: 3520px) {
  #TopReview {
    margin-top: 108px;
    background-size: 363px 207px;
    background-position: 497px 205px;
  }

  .tr-container {
    width: 1357px;
    height: 532px;
    border: 9px solid #defaff;
    border-radius: 82px;
  }

  .tr-content {
    width: 739px;
    height: 352px;
  }

  .tr-smiley {
    width: 216px;
    height: 216px;
    margin-left: 62px;
    line-height: 216px;
    font-size: 112px;
  }

  .tr-stars > i {
    width: 53px;
    height: 53px;
    line-height: 53px;
    font-size: 44px;
    margin-right: 13px;
  }

  .tr-rating {
    margin-top: 20px;
    margin-left: 48px;
  }

  .tr-rating > .tr-who > p {
    font-size: 70px;
    line-height: 82px;
  }

  .tr-rating > .tr-who > span {
    font-size: 35px;
    line-height: 42px;
  }

  .tr-content > p {
    font-size: 66px;
    line-height: 82px;
    margin-top: 57px;
  }
}

@media only screen and (min-width: 3720px) {
  #TopReview {
    margin-top: 114px;
    background-size: 384px 219px;
    background-position: 525px 217px;
  }

  .tr-container {
    width: 1434px;
    height: 562px;
    border: 10px solid #defaff;
    border-radius: 87px;
  }

  .tr-content {
    width: 781px;
    height: 372px;
  }

  .tr-smiley {
    width: 229px;
    height: 229px;
    margin-left: 66px;
    line-height: 229px;
    font-size: 118px;
  }

  .tr-stars > i {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 46px;
    margin-right: 14px;
  }

  .tr-rating {
    margin-top: 21px;
    margin-left: 50px;
  }

  .tr-rating > .tr-who > p {
    font-size: 74px;
    line-height: 87px;
  }

  .tr-rating > .tr-who > span {
    font-size: 37px;
    line-height: 45px;
  }

  .tr-content > p {
    font-size: 70px;
    line-height: 87px;
    margin-top: 60px;
  }
}

@media only screen and (min-width: 3920px) {
  #TopReview {
    margin-top: 120px;
    background-size: 404px 231px;
    background-position: 553px 229px;
  }

  .tr-container {
    width: 1511px;
    height: 592px;
    border: 10px solid #defaff;
    border-radius: 92px;
  }

  .tr-content {
    width: 823px;
    height: 392px;
  }

  .tr-smiley {
    width: 241px;
    height: 241px;
    margin-left: 69px;
    line-height: 241px;
    font-size: 125px;
  }

  .tr-stars > i {
    width: 59px;
    height: 59px;
    line-height: 59px;
    font-size: 49px;
    margin-right: 14px;
  }

  .tr-rating {
    margin-top: 22px;
    margin-left: 53px;
  }

  .tr-rating > .tr-who > p {
    font-size: 78px;
    line-height: 92px;
  }

  .tr-rating > .tr-who > span {
    font-size: 39px;
    line-height: 47px;
  }

  .tr-content > p {
    font-size: 74px;
    line-height: 92px;
    margin-top: 63px;
  }
}
