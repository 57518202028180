@import "../../variables.scss";

#Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 16px 23px 0px 23px;
}

#Header > a > img {
  width: 107px;
  height: 32px;
  background-image: url("/media/Logo-CodeLabb.svg");
  background-size: 107px 32px;
  background-repeat: no-repeat;
}

#Header > nav > .btn-mob-menu > img {
  width: 19px;
  height: 16px;
}

#Header > nav {
  vertical-align: middle;
  margin-top: 12px;
}

#Header > nav > .lnk-courses,
#Header > nav > .lnk-skills,
#Header > nav > .lnk-projects,
#Header > nav > .lnk-redeem-code,
#Header > nav > .btn-kid-login,
#Header > nav > .btn-adult-login {
  display: none;
}

@media only screen and (min-width: 320px) {
  #Header {
    padding: 19px 26px 0px 26px;
  }

  #Header > a > img {
    width: 123px;
    height: 37px;
    background-size: 123px 37px;
  }

  #Header > nav > .btn-mob-menu > img {
    width: 22px;
    height: 19px;
  }

  #Header > nav {
    margin-top: 13px;
  }
}

@media only screen and (min-width: 375px) {
  #Header {
    padding: 22px 31px 0px 31px;
  }

  #Header > a > img {
    width: 144px;
    height: 43px;
    background-size: 144px 43px;
  }

  #Header > nav > .btn-mob-menu > img {
    width: 26px;
    height: 22px;
  }

  #Header > nav {
    margin-top: 16px;
  }
}

@media only screen and (min-width: 414px) {
  #Header {
    padding: 24px 34px 0px 34px;
  }

  #Header > a > img {
    width: 159px;
    height: 48px;
    background-size: 159px 48px;
  }

  #Header > nav > .btn-mob-menu > img {
    width: 28px;
    height: 24px;
  }

  #Header > nav {
    margin-top: 17px;
  }
}

@media only screen and (min-width: 500px) {
  #Header {
    padding: 29px 41px 0px 41px;
  }

  #Header > a > img {
    width: 192px;
    height: 58px;
    background-size: 192px 58px;
  }

  #Header > nav > .btn-mob-menu > img {
    width: 34px;
    height: 29px;
  }

  #Header > nav {
    margin-top: 21px;
  }
}

@media only screen and (min-width: 600px) {
  #Header {
    padding: 35px 49px 0px 49px;
  }

  #Header > a > img {
    width: 230px;
    height: 69px;
    background-size: 230px 69px;
  }

  #Header > nav > .btn-mob-menu,
  #Header > nav > .btn-mob-menu > img {
    width: 41px;
    height: 35px;
  }

  #Header > nav {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) {
  #Header {
    padding: 35px 49px 0px 49px;
  }

  #Header > a > img {
    width: 294px;
    height: 88px;
    background-size: 294px 88px;
  }

  #Header > nav > .btn-mob-menu > img {
    width: 52px;
    height: 45px;
  }

  #Header > nav {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 992px) {
  #Header {
    justify-content: center;
    padding: 35px 57px 0px 51px;
  }

  #Header > a > img {
    width: 204px;
    height: 61px;
    margin-right: 64px;
    background-size: 204px 61px;
  }

  #Header > nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 615px;
    height: 38px;
    margin-top: 20px;
  }

  #Header > nav > .btn-mob-menu > img {
    display: none;
  }

  #Header > nav > .btn-mob-menu {
    display: none;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    display: inline-block;
    font-size: 15px;
    line-height: 38px;
  }

  #Header > nav > .btn-kid-login {
    width: 119px;
    height: 38px;
    border-radius: 10px;
    background: $color69ed82 0% 0% no-repeat padding-box;
  }
}

/* Tablet */
@media only screen and (min-width: 1024px) {
  #Header {
    padding: 36px 59px 0px 53px;
  }

  #Header > a > img {
    width: 211px;
    height: 63px;
    margin-right: 66px;
    background-size: 211px 63px;
  }

  #Header > nav {
    width: 635px;
    height: 39px;
    margin-top: 21px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 15px;
    line-height: 39px;
  }

  #Header > nav > .btn-kid-login {
    width: 123px;
    height: 39px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  #Header {
    padding: 42px 69px 0px 62px;
  }

  #Header > a > img {
    width: 247px;
    height: 74px;
    margin-right: 77px;
    background-size: 247px 74px;
  }

  #Header > nav {
    width: 744px;
    height: 46px;
    margin-top: 25px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 18px;
    line-height: 46px;
  }

  #Header > nav > .btn-kid-login {
    width: 144px;
    height: 46px;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 1366px) {
  #Header {
    padding: 36px 261px 0px 266px;
  }

  #Header > nav {
    width: 586px;
    height: 31px;
    margin-top: 19px;
  }

  #Header > a > img {
    width: 192px;
    height: 58px;
    margin-right: 60px;
    background-size: 192px 58px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 14px;
    line-height: 31px;
  }

  #Header > nav > .btn-kid-login {
    width: 113px;
    height: 31px;
    border-radius: 11px;
  }
}

@media only screen and (min-width: 1440px) {
  #Header {
    padding: 38px 275px 0px 280px;
  }

  #Header > nav {
    width: 618px;
    height: 33px;
    margin-top: 20px;
  }

  #Header > a > img {
    width: 202px;
    height: 61px;
    margin-right: 64px;
    background-size: 202px 61px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 15px;
    line-height: 33px;
  }

  #Header > nav > .btn-kid-login {
    width: 119px;
    height: 33px;
    border-radius: 11px;
  }
}

@media only screen and (min-width: 1536px) {
  #Header {
    padding: 40px 294px 0px 299px;
  }

  #Header > nav {
    width: 659px;
    height: 35px;
    margin-top: 22px;
  }

  #Header > a > img {
    width: 216px;
    height: 65px;
    margin-right: 68px;
    background-size: 216px 65px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 16px;
    line-height: 35px;
  }

  #Header > nav > .btn-kid-login {
    width: 127px;
    height: 35px;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 1600px) {
  #Header {
    padding: 42px 306px 0px 312px;
  }

  #Header > nav {
    width: 687px;
    height: 37px;
    margin-top: 22px;
  }

  #Header > a > img {
    width: 225px;
    height: 68px;
    margin-right: 71px;
    background-size: 225px 68px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 17px;
    line-height: 37px;
  }

  #Header > nav > .btn-kid-login {
    width: 132px;
    height: 37px;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 1800px) {
  #Header {
    padding: 47px 344px 0px 351px;
  }

  #Header > nav {
    width: 772px;
    height: 41px;
    margin-top: 25px;
  }

  #Header > a > img {
    width: 253px;
    height: 76px;
    margin-right: 80px;
    background-size: 253px 76px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 19px;
    line-height: 41px;
  }

  #Header > nav > .btn-kid-login {
    width: 149px;
    height: 41px;
    border-radius: 14px;
  }
}

/* Desktop */
@media only screen and (min-width: 1920px) {
  #Header {
    padding: 50px 367px 0px 374px;
  }

  #Header > nav {
    width: 824px;
    height: 44px;
    margin-top: 27px;
  }

  #Header > a > img {
    width: 270px;
    height: 81px;
    margin-right: 85px;
    background-size: 270px 81px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 20px;
    line-height: 44px;
  }

  #Header > nav > .btn-kid-login {
    width: 159px;
    height: 44px;
    border-radius: 15px;
  }
}

@media only screen and (min-width: 2120px) {
  #Header {
    padding: 55px 405px 0px 413px;
  }

  #Header > nav {
    width: 910px;
    height: 49px;
    margin-top: 30px;
  }

  #Header > a > img {
    width: 298px;
    height: 89px;
    margin-right: 94px;
    background-size: 298px 89px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 22px;
    line-height: 49px;
  }

  #Header > nav > .btn-kid-login {
    width: 176px;
    height: 49px;
    border-radius: 17px;
  }
}

@media only screen and (min-width: 2320px) {
  #Header {
    padding: 60px 443px 0px 452px;
  }

  #Header > nav {
    width: 996px;
    height: 53px;
    margin-top: 33px;
  }

  #Header > a > img {
    width: 326px;
    height: 98px;
    margin-right: 103px;
    background-size: 326px 98px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 24px;
    line-height: 53px;
  }

  #Header > nav > .btn-kid-login {
    width: 192px;
    height: 53px;
    border-radius: 18px;
  }
}

@media only screen and (min-width: 2520px) {
  #Header {
    padding: 66px 482px 0px 491px;
  }

  #Header > nav {
    width: 1082px;
    height: 58px;
    margin-top: 35px;
  }

  #Header > a > img {
    width: 354px;
    height: 106px;
    margin-right: 112px;
    background-size: 354px 106px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 26px;
    line-height: 58px;
  }

  #Header > nav > .btn-kid-login {
    width: 209px;
    height: 58px;
    border-radius: 20px;
  }
}

@media only screen and (min-width: 2720px) {
  #Header {
    padding: 71px 520px 0px 530px;
  }

  #Header > nav {
    width: 1167px;
    height: 62px;
    margin-top: 38px;
  }

  #Header > a > img {
    width: 382px;
    height: 115px;
    margin-right: 120px;
    background-size: 382px 115px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 28px;
    line-height: 62px;
  }

  #Header > nav > .btn-kid-login {
    width: 225px;
    height: 62px;
    border-radius: 21px;
  }
}

@media only screen and (min-width: 2920px) {
  #Header {
    padding: 76px 558px 0px 569px;
  }

  #Header > nav {
    width: 1253px;
    height: 67px;
    margin-top: 41px;
  }

  #Header > a > img {
    width: 411px;
    height: 123px;
    margin-right: 129px;
    background-size: 411px 123px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 30px;
    line-height: 67px;
  }

  #Header > nav > .btn-kid-login {
    width: 242px;
    height: 67px;
    border-radius: 23px;
  }
}

@media only screen and (min-width: 3120px) {
  #Header {
    padding: 81px 596px 0px 608px;
  }

  #Header > nav {
    width: 1339px;
    height: 72px;
    margin-top: 44px;
  }

  #Header > a > img {
    width: 439px;
    height: 132px;
    margin-right: 138px;
    background-size: 439px 132px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 32px;
    line-height: 72px;
  }

  #Header > nav > .btn-kid-login {
    width: 258px;
    height: 72px;
    border-radius: 24px;
  }
}

@media only screen and (min-width: 3320px) {
  #Header {
    padding: 86px 635px 0px 647px;
  }

  #Header > nav {
    width: 1425px;
    height: 76px;
    margin-top: 47px;
  }

  #Header > a > img {
    width: 467px;
    height: 140px;
    margin-right: 147px;
    background-size: 467px 140px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 35px;
    line-height: 76px;
  }

  #Header > nav > .btn-kid-login {
    width: 275px;
    height: 76px;
    border-radius: 26px;
  }
}

@media only screen and (min-width: 3520px) {
  #Header {
    padding: 92px 673px 0px 686px;
  }

  #Header > nav {
    width: 1511px;
    height: 81px;
    margin-top: 50px;
  }

  #Header > a > img {
    width: 495px;
    height: 148px;
    margin-right: 156px;
    background-size: 495px 148px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 37px;
    line-height: 81px;
  }

  #Header > nav > .btn-kid-login {
    width: 292px;
    height: 81px;
    border-radius: 28px;
  }
}

@media only screen and (min-width: 3720px) {
  #Header {
    padding: 97px 711px 0px 725px;
  }

  #Header > nav {
    width: 1596px;
    height: 85px;
    margin-top: 52px;
  }

  #Header > a > img {
    width: 523px;
    height: 157px;
    margin-right: 165px;
    background-size: 523px 157px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 39px;
    line-height: 85px;
  }

  #Header > nav > .btn-kid-login {
    width: 308px;
    height: 85px;
    border-radius: 29px;
  }
}

@media only screen and (min-width: 3920px) {
  #Header {
    padding: 102px 749px 0px 764px;
  }

  #Header > nav {
    width: 1682px;
    height: 90px;
    margin-top: 55px;
  }

  #Header > a > img {
    width: 551px;
    height: 165px;
    margin-right: 174px;
    background-size: 551px 165px;
  }

  #Header > nav > .lnk-courses,
  #Header > nav > .lnk-skills,
  #Header > nav > .lnk-projects,
  #Header > nav > .lnk-redeem-code,
  #Header > nav > .btn-kid-login,
  #Header > nav > .btn-adult-login {
    font-size: 41px;
    line-height: 90px;
  }

  #Header > nav > .btn-kid-login {
    width: 325px;
    height: 90px;
    border-radius: 31px;
  }
}
