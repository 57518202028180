@import "variables.scss";

@font-face {
  font-family: "Gilroy-Light";
  src: url("/media/fonts/Gilroy-Light.woff2") format("woff2")
      /* Super Modern Browsers */,
    url("/media/fonts/Gilroy-Light.woff") format("woff")
      /* Pretty Modern Browsers */,
    url("/media/fonts/Gilroy-Light.ttf") format("truetype")
      /* Safari, Android, iOS */,
    url("/media/fonts/Gilroy-Light.svg#Gilroy-Light") format("svg")
      /* Legacy iOS */; /* Legacy iOS */
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("/media/fonts/Gilroy-Regular.woff2") format("woff2")
      /* Super Modern Browsers */,
    url("/media/fonts/Gilroy-Regular.woff") format("woff")
      /* Pretty Modern Browsers */,
    url("/media/fonts/Gilroy-Regular.ttf") format("truetype")
      /* Safari, Android, iOS */,
    url("/media/fonts/Gilroy-Regular.svg#Gilroy-Regular") format("svg")
      /* Legacy iOS */; /* Legacy iOS */
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("/media/fonts/Gilroy-ExtraBold.woff2") format("woff2")
      /* Super Modern Browsers */,
    url("/media/fonts/Gilroy-ExtraBold.woff") format("woff")
      /* Pretty Modern Browsers */,
    url("/media/fonts/Gilroy-ExtraBold.ttf") format("truetype")
      /* Safari, Android, iOS */,
    url("/media/fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold") format("svg")
      /* Legacy iOS */; /* Legacy iOS */
}

@font-face {
  font-family: "JetBrainsMono";
  src: url("/media/fonts/JetBrainsMono-Regular.eot");
  src: url("/media/fonts/JetBrainsMono-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/JetBrainsMono-Regular.svg#JetBrainsMono-Regular")
      format("svg"),
    url("/media/fonts/JetBrainsMono-Regular.ttf") format("truetype"),
    url("/media/fonts/JetBrainsMono-Regular.woff") format("woff"),
    url("/media/fonts/JetBrainsMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JetBrainsMono-Bold";
  src: url("/media/fonts/JetBrainsMono-Bold.eot");
  src: url("/media/fonts/JetBrainsMono-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/JetBrainsMono-Bold.svg#JetBrainsMono-Bold") format("svg"),
    url("/media/fonts/JetBrainsMono-Bold.ttf") format("truetype"),
    url("/media/fonts/JetBrainsMono-Bold.woff") format("woff"),
    url("/media/fonts/JetBrainsMono-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Gilroy-Light", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $color7d1cc9;
  background: $colorf5feff 0% 0% no-repeat padding-box;
}

.body-text {
  color: $color7d1cc9;
}

.bold-font {
  font-family: "Gilroy-ExtraBold", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 900;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $color7d1cc9;
}

a,
a:active,
a:hover,
button {
  font-family: "Gilroy-ExtraBold", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 900;
  text-decoration: none;
  color: $color7d1cc9;
}

a:visited {
  color: $color7d1cc9;
}

h1,
h2 {
  font-family: "Gilroy-ExtraBold", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0px;
}

h1 {
  font-size: 25px;
}

h2 {
  color: $coloref2996;
  font-size: 22px;
}

h3 {
  font-family: "Gilroy-ExtraBold", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 900;
  font-size: 22px;
}

p {
  font-size: 14px;
  line-height: 19px;
}

i {
  font-style: normal;
}

strong {
  font-family: "Gilroy-ExtraBold", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 900;
}

button {
  background-color: transparent;
  border: none;
}

.highlight {
  color: $coloref2996;
}

.highlight-bg {
  color: $coloref2996;
  background-color: $colorffeea4;
  border-radius: 28px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
}

.highlight-bg-blue {
  color: $coloref2996;
  background-color: $colorb3f1ff;
  border-radius: 28px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
}

.highlight-bg-yellow {
  color: $coloref2996;
  background-color: $colorffeea4;
  border-radius: 28px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
}

.highlight-bg-green {
  color: $coloref2996;
  background-color: $colorebffeb;
  border-radius: 28px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
}

/* Between mobile and tablet #1 */
@media only screen and (min-width: 500px) {
  h1 {
    font-size: 36px;
    line-height: 45px;
  }

  h2 {
    color: $coloref2996;
    font-size: 26px;
    line-height: 33px;
  }
}
/* Between mobile and tablet #2 */
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 46px;
    line-height: 55px;
  }

  h2 {
    color: $coloref2996;
    font-size: 36px;
    line-height: 43px;
  }
}

/* Tablet */
@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 69px;
    line-height: 83px;
  }

  h2 {
    color: $coloref2996;
    font-size: 57px;
    line-height: 69px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
  }
}

/* Desktop */
@media only screen and (min-width: 1366px) {
  h1 {
    font-size: 89px;
    line-height: 107px;
  }

  h2 {
    color: $coloref2996;
    font-size: 73px;
    line-height: 88px;
  }

  p {
    font-size: 28px;
    line-height: 39px;
  }
}

@media only screen and (min-width: 1366px) {
  /* TODO */
}

/* Desktop */
@media only screen and (min-width: 1800px) {
  h1 {
    font-size: 89px;
    line-height: 107px;
  }

  h2 {
    color: $coloref2996;
    font-size: 73px;
    line-height: 88px;
  }

  p {
    font-size: 28px;
    line-height: 39px;
  }
}

/* 2.5k */
@media only screen and (min-width: 2500px) {
  h1 {
    font-size: 148px;
    line-height: 184px;
  }

  h2 {
    color: $coloref2996;
    font-size: 116px;
    line-height: 146px;
  }

  p {
    font-size: 45px;
    line-height: 58px;
  }
}

/* 4k */
@media only screen and (min-width: 3000px) {
  h1 {
    font-size: 178px;
    line-height: 214px;
  }

  h2 {
    color: $coloref2996;
    font-size: 146px;
    line-height: 176px;
  }

  p {
    font-size: 65px;
    line-height: 78px;
  }
}
