@import "../../variables.scss";

@media only screen and (min-width: 992px) {
  .cr-container {
    width: 213px;
    height: 174px;
    display: flex;
    flex-direction: column;
  }

  .cr-content {
    width: 213px;
    height: 174px;
  }

  .cr-smiley {
    display: inline-block;
    width: 73px;
    height: 73px;
    background: $coloref2996 0% 0% no-repeat padding-box;
    border-radius: 50%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 36px;
    font-size: 30px;
    color: #ffffff;
    padding-top: 18px;
  }

  .cr-stars > i {
    width: 17px;
    height: 17px;
    background: $colorffea6c 0% 0% no-repeat padding-box;
    border-radius: 50%;
    background-position: 50% 50%;
    text-align: center;
    vertical-align: bottom;
    line-height: 17px;
    font-size: 14px;
    color: #ffffff;
    margin-right: 3px;
  }

  .cr-stars i:before,
  .cr-stars i:after {
    color: #ffffff;
  }

  .cr-rating {
    display: inline-block;
    vertical-align: top;
    margin-top: 11px;
    margin-left: 15px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 20px;
    line-height: 34px;
  }

  .cr-rating > .cr-who > span {
    position: relative;
    top: -5px;
    font-size: 12px;
  }

  .cr-content > p {
    font-size: 19px;
    line-height: 25px;
    margin-top: 15px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1024px) {
  .cr-container {
    width: 220px;
    height: 180px;
  }

  .cr-content {
    width: 220px;
    height: 180px;
  }

  .cr-smiley {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    line-height: 37px;
    font-size: 31px;
    padding-top: 19px;
  }

  .cr-stars > i {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 18px;
    font-size: 14px;
    margin-right: 3px;
  }

  .cr-rating {
    margin-top: 11px;
    margin-left: 15px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 21px;
    line-height: 35px;
  }

  .cr-rating > .cr-who > span {
    top: -5px;
    font-size: 12px;
  }

  .cr-content > p {
    font-size: 20px;
    line-height: 26px;
    margin-top: 15px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .cr-container {
    width: 258px;
    height: 211px;
  }

  .cr-content {
    width: 258px;
    height: 211px;
  }

  .cr-smiley {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    line-height: 43px;
    font-size: 36px;
    padding-top: 22px;
  }

  .cr-stars > i {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 21px;
    font-size: 16px;
    margin-right: 4px;
  }

  .cr-rating {
    margin-top: 13px;
    margin-left: 18px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 25px;
    line-height: 41px;
  }

  .cr-rating > .cr-who > span {
    top: -6px;
    font-size: 14px;
  }

  .cr-content > p {
    font-size: 23px;
    line-height: 30px;
    margin-top: 18px;
    margin-left: 18px;
  }
}

@media only screen and (min-width: 1366px) {
  .cr-container {
    width: 181px;
    height: 147px;
  }

  .cr-content {
    width: 221px;
    height: 147px;
  }

  .cr-smiley {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    line-height: 35px;
    font-size: 29px;
    padding-top: 16px;
  }

  .cr-stars > i {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 16px;
    font-size: 14px;
    margin-right: 2px;
  }

  .cr-rating {
    margin-top: 8px;
    margin-left: 18px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 23px;
    line-height: 36px;
  }

  .cr-rating > .cr-who > span {
    top: -4px;
    font-size: 12px;
  }

  .cr-content > p {
    font-size: 14px;
    line-height: 18px;
    margin-top: 18px;
    margin-left: 11px;
  }
}

@media only screen and (min-width: 1440px) {
  .cr-container {
    width: 191px;
    height: 155px;
  }

  .cr-content {
    width: 232px;
    height: 155px;
  }

  .cr-smiley {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    line-height: 37px;
    font-size: 31px;
    padding-top: 16px;
  }

  .cr-stars > i {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 17px;
    font-size: 14px;
    margin-right: 2px;
  }

  .cr-rating {
    margin-top: 8px;
    margin-left: 19px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 24px;
    line-height: 38px;
  }

  .cr-rating > .cr-who > span {
    top: -4px;
    font-size: 13px;
  }

  .cr-content > p {
    font-size: 15px;
    line-height: 20px;
    margin-top: 19px;
    margin-left: 11px;
  }
}

@media only screen and (min-width: 1536px) {
  .cr-container {
    width: 204px;
    height: 166px;
  }

  .cr-content {
    width: 248px;
    height: 166px;
  }

  .cr-smiley {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    line-height: 39px;
    font-size: 33px;
    padding-top: 18px;
  }

  .cr-stars > i {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 18px;
    font-size: 15px;
    margin-right: 2px;
  }

  .cr-rating {
    margin-top: 9px;
    margin-left: 20px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 26px;
    line-height: 40px;
  }

  .cr-rating > .cr-who > span {
    top: -4px;
    font-size: 14px;
  }

  .cr-content > p {
    font-size: 16px;
    line-height: 21px;
    margin-top: 20px;
    margin-left: 12px;
  }
}

@media only screen and (min-width: 1600px) {
  .cr-container {
    width: 212px;
    height: 172px;
  }

  .cr-content {
    width: 258px;
    height: 172px;
  }

  .cr-smiley {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    line-height: 41px;
    font-size: 34px;
    padding-top: 18px;
  }

  .cr-stars > i {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 19px;
    font-size: 16px;
    margin-right: 2px;
  }

  .cr-rating {
    margin-top: 9px;
    margin-left: 21px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 27px;
    line-height: 42px;
  }

  .cr-rating > .cr-who > span {
    top: -4px;
    font-size: 14px;
  }

  .cr-content > p {
    font-size: 17px;
    line-height: 22px;
    margin-top: 21px;
    margin-left: 12px;
  }
}

@media only screen and (min-width: 1800px) {
  .cr-container {
    width: 239px;
    height: 194px;
  }

  .cr-content {
    width: 291px;
    height: 194px;
  }

  .cr-smiley {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    line-height: 46px;
    font-size: 38px;
    padding-top: 21px;
  }

  .cr-stars > i {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 22px;
    font-size: 18px;
    margin-right: 3px;
  }

  .cr-rating {
    margin-top: 10px;
    margin-left: 23px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 30px;
    line-height: 47px;
  }

  .cr-rating > .cr-who > span {
    top: -5px;
    font-size: 16px;
  }

  .cr-content > p {
    font-size: 19px;
    line-height: 24px;
    margin-top: 23px;
    margin-left: 14px;
  }
}

@media only screen and (min-width: 1920px) {
  .cr-container {
    width: 255px;
    height: 207px;
  }

  .cr-content {
    width: 310px;
    height: 207px;
  }

  .cr-smiley {
    width: 93px;
    height: 93px;
    border-radius: 50%;
    line-height: 49px;
    font-size: 41px;
    padding-top: 22px;
  }

  .cr-stars > i {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 23px;
    font-size: 19px;
    margin-right: 3px;
  }

  .cr-rating {
    margin-top: 11px;
    margin-left: 25px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 32px;
    line-height: 50px;
  }

  .cr-rating > .cr-who > span {
    top: -5px;
    font-size: 17px;
  }

  .cr-content > p {
    font-size: 20px;
    line-height: 26px;
    margin-top: 25px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 2120px) {
  .cr-container {
    width: 282px;
    height: 229px;
  }

  .cr-content {
    width: 342px;
    height: 229px;
  }

  .cr-smiley {
    width: 103px;
    height: 103px;
    border-radius: 50%;
    line-height: 54px;
    font-size: 45px;
    padding-top: 24px;
  }

  .cr-stars > i {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 25px;
    font-size: 21px;
    margin-right: 3px;
  }

  .cr-rating {
    margin-top: 12px;
    margin-left: 28px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 35px;
    line-height: 55px;
  }

  .cr-rating > .cr-who > span {
    top: -6px;
    font-size: 19px;
  }

  .cr-content > p {
    font-size: 22px;
    line-height: 29px;
    margin-top: 28px;
    margin-left: 17px;
  }
}

@media only screen and (min-width: 2320px) {
  .cr-container {
    width: 308px;
    height: 250px;
  }

  .cr-content {
    width: 375px;
    height: 250px;
  }

  .cr-smiley {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    line-height: 59px;
    font-size: 50px;
    padding-top: 27px;
  }

  .cr-stars > i {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 28px;
    font-size: 23px;
    margin-right: 4px;
  }

  .cr-rating {
    margin-top: 13px;
    margin-left: 30px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 39px;
    line-height: 60px;
  }

  .cr-rating > .cr-who > span {
    top: -6px;
    font-size: 21px;
  }

  .cr-content > p {
    font-size: 24px;
    line-height: 31px;
    margin-top: 30px;
    margin-left: 18px;
  }
}

@media only screen and (min-width: 2520px) {
  .cr-container {
    width: 335px;
    height: 272px;
  }

  .cr-content {
    width: 407px;
    height: 272px;
  }

  .cr-smiley {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    line-height: 64px;
    font-size: 54px;
    padding-top: 29px;
  }

  .cr-stars > i {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 30px;
    font-size: 25px;
    margin-right: 4px;
  }

  .cr-rating {
    margin-top: 14px;
    margin-left: 33px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 42px;
    line-height: 66px;
  }

  .cr-rating > .cr-who > span {
    top: -7px;
    font-size: 22px;
  }

  .cr-content > p {
    font-size: 26px;
    line-height: 34px;
    margin-top: 33px;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 2720px) {
  .cr-container {
    width: 361px;
    height: 293px;
  }

  .cr-content {
    width: 439px;
    height: 293px;
  }

  .cr-smiley {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    line-height: 69px;
    font-size: 58px;
    padding-top: 31px;
  }

  .cr-stars > i {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 33px;
    font-size: 27px;
    margin-right: 4px;
  }

  .cr-rating {
    margin-top: 16px;
    margin-left: 35px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 45px;
    line-height: 71px;
  }

  .cr-rating > .cr-who > span {
    top: -7px;
    font-size: 24px;
  }

  .cr-content > p {
    font-size: 28px;
    line-height: 37px;
    margin-top: 35px;
    margin-left: 21px;
  }
}

@media only screen and (min-width: 2920px) {
  .cr-container {
    width: 388px;
    height: 315px;
  }

  .cr-content {
    width: 471px;
    height: 315px;
  }

  .cr-smiley {
    width: 141px;
    height: 141px;
    border-radius: 50%;
    line-height: 75px;
    font-size: 62px;
    padding-top: 33px;
  }

  .cr-stars > i {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 35px;
    font-size: 29px;
    margin-right: 5px;
  }

  .cr-rating {
    margin-top: 17px;
    margin-left: 38px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 49px;
    line-height: 76px;
  }

  .cr-rating > .cr-who > span {
    top: -8px;
    font-size: 26px;
  }

  .cr-content > p {
    font-size: 30px;
    line-height: 40px;
    margin-top: 38px;
    margin-left: 23px;
  }
}

@media only screen and (min-width: 3120px) {
  .cr-container {
    width: 414px;
    height: 336px;
  }

  .cr-content {
    width: 504px;
    height: 336px;
  }

  .cr-smiley {
    width: 151px;
    height: 151px;
    border-radius: 50%;
    line-height: 80px;
    font-size: 67px;
    padding-top: 36px;
  }

  .cr-stars > i {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 37px;
    font-size: 31px;
    margin-right: 5px;
  }

  .cr-rating {
    margin-top: 18px;
    margin-left: 41px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 52px;
    line-height: 81px;
  }

  .cr-rating > .cr-who > span {
    top: -8px;
    font-size: 28px;
  }

  .cr-content > p {
    font-size: 32px;
    line-height: 42px;
    margin-top: 41px;
    margin-left: 24px;
  }
}

@media only screen and (min-width: 3320px) {
  .cr-container {
    width: 441px;
    height: 358px;
  }

  .cr-content {
    width: 536px;
    height: 358px;
  }

  .cr-smiley {
    width: 161px;
    height: 161px;
    border-radius: 50%;
    line-height: 85px;
    font-size: 71px;
    padding-top: 38px;
  }

  .cr-stars > i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 40px;
    font-size: 33px;
    margin-right: 5px;
  }

  .cr-rating {
    margin-top: 19px;
    margin-left: 43px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 55px;
    line-height: 86px;
  }

  .cr-rating > .cr-who > span {
    top: -9px;
    font-size: 29px;
  }

  .cr-content > p {
    font-size: 35px;
    line-height: 45px;
    margin-top: 43px;
    margin-left: 26px;
  }
}

@media only screen and (min-width: 3520px) {
  .cr-container {
    width: 468px;
    height: 380px;
  }

  .cr-content {
    width: 568px;
    height: 380px;
  }

  .cr-smiley {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    line-height: 90px;
    font-size: 75px;
    padding-top: 40px;
  }

  .cr-stars > i {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 42px;
    font-size: 35px;
    margin-right: 6px;
  }

  .cr-rating {
    margin-top: 20px;
    margin-left: 46px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 59px;
    line-height: 92px;
  }

  .cr-rating > .cr-who > span {
    top: -9px;
    font-size: 31px;
  }

  .cr-content > p {
    font-size: 37px;
    line-height: 48px;
    margin-top: 46px;
    margin-left: 28px;
  }
}

@media only screen and (min-width: 3720px) {
  .cr-container {
    width: 494px;
    height: 401px;
  }

  .cr-content {
    width: 601px;
    height: 401px;
  }

  .cr-smiley {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    line-height: 95px;
    font-size: 79px;
    padding-top: 43px;
  }

  .cr-stars > i {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 45px;
    font-size: 37px;
    margin-right: 6px;
  }

  .cr-rating {
    margin-top: 21px;
    margin-left: 48px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 62px;
    line-height: 97px;
  }

  .cr-rating > .cr-who > span {
    top: -10px;
    font-size: 33px;
  }

  .cr-content > p {
    font-size: 39px;
    line-height: 50px;
    margin-top: 48px;
    margin-left: 29px;
  }
}

@media only screen and (min-width: 3920px) {
  .cr-container {
    width: 521px;
    height: 423px;
  }

  .cr-content {
    width: 633px;
    height: 423px;
  }

  .cr-smiley {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    line-height: 100px;
    font-size: 84px;
    padding-top: 45px;
  }

  .cr-stars > i {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-position: 50% 50%;
    line-height: 47px;
    font-size: 39px;
    margin-right: 6px;
  }

  .cr-rating {
    margin-top: 22px;
    margin-left: 51px;
  }

  .cr-rating > .cr-who > p {
    font-weight: 900;
    font-size: 65px;
    line-height: 102px;
  }

  .cr-rating > .cr-who > span {
    top: -10px;
    font-size: 35px;
  }

  .cr-content > p {
    font-size: 41px;
    line-height: 53px;
    margin-top: 51px;
    margin-left: 31px;
  }
}
