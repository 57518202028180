#MeetOurTeam {
  display: none;
}

@media only screen and (min-width: 992px) {
  #MeetOurTeam {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='215.385' height='114.657'%3E%3Cdefs%3E%3Cstyle%3E.b%7Bfill:%23defaff%7D%3C/style%3E%3C/defs%3E%3Cpath d='M215.332 72.56c-.911-11.924-11-19.114-22.312-19.114a16.63 16.63 0 00-7.09 1.631 43.231 43.231 0 00-12.054-17.709q-1.7-1.565-3.41-3.131c-10.787-9.91-27.188-12.749-40.863-7.943a39.578 39.578 0 00-5.328 2.358c-.062-.151-.111-.333-.176-.479a80 80 0 00-5.243-10.255C111.215 5.756 96.125-.967 82.027.112a40.984 40.984 0 00-33.284 22.453 39.817 39.817 0 00-2.174 5.353 47.417 47.417 0 00-5.63.025 59 59 0 00-11.966 2.543 41.728 41.728 0 00-17.47 11.785 41 41 0 00-4 5.475 42.448 42.448 0 00-6.73 16.03 42.707 42.707 0 0074.59 35.69 32.832 32.832 0 0010.778 8.669 33.2 33.2 0 0033.07-2.2 34.216 34.216 0 001.5-1.084 42.927 42.927 0 0062.108-13.853 24.283 24.283 0 0013.407 3.878c11.317-.005 19.917-11.69 19.106-22.316z' fill='%23fff'/%3E%3Cpath class='b' d='M134.728 36.735a20.211 20.211 0 0135.025 14.15c-3.367-5.866-8.778-9.605-14.967-12.353a32.922 32.922 0 00-20.058-1.797zM16.493 56.727a20.211 20.211 0 0134.771-14.762c-6.527-1.766-13-.58-19.316 1.85a32.945 32.945 0 00-15.455 12.912zM99.311 53.322a18.972 18.972 0 01-35.346-2.814c4.669 4.309 10.567 6.131 16.879 6.852a30.943 30.943 0 0018.467-4.038zM141.486 80.472a18.97 18.97 0 01-35.345-2.814c4.667 4.31 10.567 6.131 16.877 6.852a30.952 30.952 0 0018.468-4.038z'/%3E%3Cpath class='b' d='M201.356 76.598a42.2 42.2 0 01-23.873-7.457A42.587 42.587 0 01139.6 93.054a41.28 41.28 0 01-18.048-4.211 42.046 42.046 0 01-48.867-1.889 41.731 41.731 0 01-58.434-2.4A43.588 43.588 0 01.198 67.945 42.69 42.69 0 0075.36 99.464a32.834 32.834 0 0010.778 8.668 33.2 33.2 0 0033.07-2.2 34.216 34.216 0 001.5-1.084 42.927 42.927 0 0062.108-13.853 24.283 24.283 0 0013.407 3.878c10.772 0 19.06-10.569 19.148-20.746a41.641 41.641 0 01-14.015 2.471z'/%3E%3C/svg%3E");
    background-size: 248px 132px;
    background-position: 732px 64px;
    background-repeat: no-repeat;
    margin-top: 85px;
    height: 953px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 49px;
    line-height: 59px;
    text-align: center;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 44px;
  }

  #MeetOurTeam > .mot-container > .mot-team > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 198px;
    margin-left: 135px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 198px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 198px;
    margin-right: 126px;
  }
}

@media only screen and (min-width: 1024px) {
  #MeetOurTeam {
    background-size: 256px 136px;
    background-position: 756px 66px;
    margin-top: 88px;
    height: 984px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 51px;
    line-height: 61px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 45px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 204px;
    margin-left: 139px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 204px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 204px;
    margin-right: 130px;
  }
}

@media only screen and (min-width: 1200px) {
  #MeetOurTeam {
    background-size: 300px 159px;
    background-position: 886px 77px;
    margin-top: 103px;
    height: 1153px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 60px;
    line-height: 71px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 53px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 239px;
    margin-left: 163px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 239px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 239px;
    margin-right: 152px;
  }
}

@media only screen and (min-width: 1366px) {
  #MeetOurTeam {
    background-size: 248px 132px;
    background-position: 925px 65px;
    margin-top: 94px;
    height: 1078px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 51px;
    line-height: 60px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 28px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 198px;
    margin-left: 315px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 198px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 198px;
    margin-right: 317px;
  }
}

@media only screen and (min-width: 1440px) {
  #MeetOurTeam {
    background-size: 262px 140px;
    background-position: 975px 68px;
    margin-top: 99px;
    height: 1136px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 53px;
    line-height: 64px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 29px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 208px;
    margin-left: 332px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 208px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 208px;
    margin-right: 334px;
  }
}

@media only screen and (min-width: 1536px) {
  #MeetOurTeam {
    background-size: 279px 149px;
    background-position: 1040px 73px;
    margin-top: 106px;
    height: 1212px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 57px;
    line-height: 68px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 31px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 222px;
    margin-left: 354px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 222px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 222px;
    margin-right: 356px;
  }
}

@media only screen and (min-width: 1600px) {
  #MeetOurTeam {
    background-size: 291px 155px;
    background-position: 1083px 76px;
    margin-top: 110px;
    height: 1262px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 59px;
    line-height: 71px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 32px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 232px;
    margin-left: 369px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 232px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 232px;
    margin-right: 371px;
  }
}

@media only screen and (min-width: 1800px) {
  #MeetOurTeam {
    background-size: 327px 174px;
    background-position: 1219px 85px;
    margin-top: 124px;
    height: 1420px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 67px;
    line-height: 80px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 37px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 261px;
    margin-left: 415px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 261px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 261px;
    margin-right: 417px;
  }
}

@media only screen and (min-width: 1920px) {
  #MeetOurTeam {
    background-size: 349px 186px;
    background-position: 1300px 91px;
    margin-top: 132px;
    height: 1515px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 71px;
    line-height: 85px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 39px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 278px;
    margin-left: 443px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 278px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 278px;
    margin-right: 445px;
  }
}

@media only screen and (min-width: 2120px) {
  #MeetOurTeam {
    background-size: 385px 205px;
    background-position: 1435px 100px;
    margin-top: 146px;
    height: 1673px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 78px;
    line-height: 94px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 43px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 307px;
    margin-left: 489px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 307px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 307px;
    margin-right: 491px;
  }
}

@media only screen and (min-width: 2320px) {
  #MeetOurTeam {
    background-size: 422px 225px;
    background-position: 1571px 110px;
    margin-top: 160px;
    height: 1831px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 86px;
    line-height: 103px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 47px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 336px;
    margin-left: 535px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 336px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 336px;
    margin-right: 538px;
  }
}

@media only screen and (min-width: 2520px) {
  #MeetOurTeam {
    background-size: 458px 244px;
    background-position: 1706px 119px;
    margin-top: 173px;
    height: 1988px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 93px;
    line-height: 112px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 51px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 365px;
    margin-left: 581px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 365px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 365px;
    margin-right: 584px;
  }
}

@media only screen and (min-width: 2720px) {
  #MeetOurTeam {
    background-size: 494px 264px;
    background-position: 1842px 129px;
    margin-top: 187px;
    height: 2146px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 101px;
    line-height: 120px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 55px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 394px;
    margin-left: 628px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 394px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 394px;
    margin-right: 630px;
  }
}

@media only screen and (min-width: 2920px) {
  #MeetOurTeam {
    background-size: 531px 283px;
    background-position: 1977px 138px;
    margin-top: 201px;
    height: 2304px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 108px;
    line-height: 129px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 59px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 423px;
    margin-left: 674px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 423px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 423px;
    margin-right: 677px;
  }
}

@media only screen and (min-width: 3120px) {
  #MeetOurTeam {
    background-size: 567px 302px;
    background-position: 2112px 148px;
    margin-top: 214px;
    height: 2462px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 115px;
    line-height: 138px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 63px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 452px;
    margin-left: 720px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 452px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 452px;
    margin-right: 723px;
  }
}

@media only screen and (min-width: 3320px) {
  #MeetOurTeam {
    background-size: 603px 322px;
    background-position: 2248px 157px;
    margin-top: 228px;
    height: 2620px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 123px;
    line-height: 147px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 67px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 481px;
    margin-left: 766px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 481px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 481px;
    margin-right: 769px;
  }
}

@media only screen and (min-width: 3520px) {
  #MeetOurTeam {
    background-size: 640px 341px;
    background-position: 2383px 167px;
    margin-top: 242px;
    height: 2778px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 130px;
    line-height: 156px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 72px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 510px;
    margin-left: 812px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 510px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 510px;
    margin-right: 816px;
  }
}

@media only screen and (min-width: 3720px) {
  #MeetOurTeam {
    background-size: 676px 360px;
    background-position: 2519px 176px;
    margin-top: 256px;
    height: 2935px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 138px;
    line-height: 165px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 76px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 539px;
    margin-left: 858px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 539px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 539px;
    margin-right: 862px;
  }
}

@media only screen and (min-width: 3920px) {
  #MeetOurTeam {
    background-size: 713px 380px;
    background-position: 2654px 186px;
    margin-top: 270px;
    height: 3093px;
  }

  #MeetOurTeam > .mot-container > .mot-title-container > h3 {
    font-size: 145px;
    line-height: 174px;
  }

  #MeetOurTeam > .mot-container > .mot-team {
    margin-top: 80px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-1 {
    width: 568px;
    margin-left: 904px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-2 {
    width: 568px;
  }

  #MeetOurTeam > .mot-container > .mot-team > .not-col-3 {
    width: 568px;
    margin-right: 909px;
  }
}
