@media only screen and (min-width: 992px) {
  .MeetOurTeamMember {
    width: 198px;
    height: 301px;
  }

  .MeetOurTeamMember > .motm-container {
    position: relative;
    height: 301px;
    width: 135px;
  }

  .MeetOurTeamMember {
    margin-top: -10px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 126px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 126px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    position: absolute;
    top: 0px;
    left: 11px;
    background-size: 136px 135px;
    background-position: left 0px bottom 0px;
    background-repeat: no-repeat;
    width: 136px;
    height: 170px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member.motm-c-green {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='139.75' height='139.234'%3E%3Cpath d='M69.881 0c.25 0 .494.015.743.018v38.516c-.25-.006-.491-.034-.743-.034-17.14 0-30.325 12.395-30.325 31.118s13.185 31.117 30.325 31.117c16.983 0 30.063-12.175 30.3-30.607h39.569c-.269 39.042-31.273 69.107-69.87 69.107-38.764 0-69.881-30.325-69.881-69.617S31.117 0 69.881 0z' fill='%237cdd8a'/%3E%3C/svg%3E");
  }

  .MeetOurTeamMember > .motm-container > .motm-member.motm-c-yellow {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='139.75' height='139.235'%3E%3Cpath d='M69.881 0c.249 0 .494.016.742.019v38.516c-.249-.006-.491-.034-.742-.034-17.141 0-30.326 12.395-30.326 31.118s13.185 31.116 30.326 31.116c16.982 0 30.063-12.174 30.3-30.607h39.566c-.269 39.043-31.273 69.108-69.869 69.108-38.764 0-69.881-30.325-69.881-69.617S31.117 0 69.881 0z' fill='%23ffea6c'/%3E%3C/svg%3E");
  }

  .MeetOurTeamMember > .motm-container > .motm-member.motm-c-purple {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='139.75' height='139.235'%3E%3Cpath d='M69.881 0c.249 0 .494.016.742.019v38.515c-.25 0-.491-.033-.742-.033-17.141 0-30.326 12.395-30.326 31.118s13.185 31.116 30.326 31.116c16.982 0 30.063-12.174 30.3-30.607h39.569c-.269 39.043-31.273 69.108-69.869 69.108C31.116 139.236 0 108.912 0 69.619S31.12 0 69.881 0z' fill='%237d1cc9'/%3E%3C/svg%3E");
  }

  .MeetOurTeamMember > .motm-container > .motm-member.motm-c-pink {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='139.75' height='139.235'%3E%3Cpath d='M69.881 0c.249 0 .494.016.742.019v38.515c-.25 0-.491-.033-.742-.033-17.141 0-30.326 12.395-30.326 31.117s13.185 31.117 30.326 31.117c16.982 0 30.063-12.174 30.3-30.608h39.569c-.269 39.044-31.273 69.109-69.869 69.109C31.116 139.236 0 108.911 0 69.618S31.12 0 69.881 0z' fill='%23e5309c'/%3E%3C/svg%3E");
  }

  .MeetOurTeamMember img {
    position: absolute;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: 136px;
    background-position: center 12px;
    width: 136px;
    height: 170px;
    clip-path: ellipse(55% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 194px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-136px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 131px;
    background-position: center -3px;
  }

  .MeetOurTeamMember.motm-lea img {
    background-image: url("/media/home/team/lea-136px.png");
  }

  .MeetOurTeamMember.motm-mike img {
    background-image: url("/media/home/team/mike-136px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 1px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 194px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-136px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    position: absolute;
    top: 36px;
    left: 124px;
    font-size: 22px;
    line-height: 22px;
    width: 62px;
  }

  .MeetOurTeamMember > .motm-container > p {
    position: absolute;
    top: 188px;
    font-size: 15px;
    line-height: 20px;
    width: 198px;
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  .MeetOurTeamMember {
    width: 204px;
    height: 311px;
  }

  .MeetOurTeamMember > .motm-container {
    height: 311px;
    width: 139px;
  }

  .MeetOurTeamMember {
    margin-top: -10px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 130px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 130px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 11px;
    background-size: 140px 139px;
    width: 140px;
    height: 175px;
  }

  .MeetOurTeamMember img {
    background-size: 140px;
    background-position: center 12px;
    width: 140px;
    height: 175px;
    clip-path: ellipse(55% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 200px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-140px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 135px;
    background-position: center -3px;
  }

  .MeetOurTeamMember.motm-lea img {
    background-image: url("/media/home/team/lea-140px.png");
  }

  .MeetOurTeamMember.motm-mike img {
    background-image: url("/media/home/team/mike-140px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 1px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 200px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-140px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 37px;
    left: 128px;
    font-size: 23px;
    line-height: 23px;
    width: 64px;
  }
  .MeetOurTeamMember > .motm-container > p {
    top: 194px;
    font-size: 15px;
    line-height: 21px;
    width: 204px;
  }
}

@media only screen and (min-width: 1200px) {
  .MeetOurTeamMember {
    width: 239px;
    height: 364px;
  }

  .MeetOurTeamMember > .motm-container {
    height: 364px;
    width: 163px;
  }

  .MeetOurTeamMember {
    margin-top: -12px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 152px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 152px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 13px;
    background-size: 164px 163px;
    width: 164px;
    height: 205px;
  }

  .MeetOurTeamMember img {
    background-size: 164px;
    background-position: center 14px;
    width: 164px;
    height: 205px;
    clip-path: ellipse(55% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 234px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-164px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 158px;
    background-position: center -4px;
  }

  .MeetOurTeamMember.motm-lea img {
    background-image: url("/media/home/team/lea-164px.png");
  }

  .MeetOurTeamMember.motm-mike img {
    background-image: url("/media/home/team/mike-164px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 1px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 234px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-164px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 43px;
    left: 150px;
    font-size: 27px;
    line-height: 27px;
    width: 75px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 227px;
    font-size: 18px;
    line-height: 25px;
    width: 239px;
  }
}

@media only screen and (min-width: 1366px) {
  .MeetOurTeamMember {
    width: 198px;
    height: 290px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 198px;
    height: 290px;
  }

  .MeetOurTeamMember {
    margin-top: -9px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 108px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 108px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 9px;
    background-size: 136px 135px;
    width: 136px;
    height: 183px;
  }

  .MeetOurTeamMember img {
    background-size: 136px;
    background-position: center 24px;
    width: 136px;
    height: 183px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 203px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-136px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 136px;
    background-position: center 5px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-136px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 32px;
    background-image: url("/media/home/team/mike-136px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 203px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-136px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 51px;
    left: 123px;
    font-size: 23px;
    line-height: 21px;
    width: 65px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 201px;
    font-size: 15px;
    line-height: 20px;
    width: 198px;
  }
}

@media only screen and (min-width: 1440px) {
  .MeetOurTeamMember {
    width: 208px;
    height: 306px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 208px;
    height: 306px;
  }

  .MeetOurTeamMember {
    margin-top: -9px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 114px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 114px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 10px;
    background-size: 143px 142px;
    width: 143px;
    height: 193px;
  }

  .MeetOurTeamMember img {
    background-size: 143px;
    background-position: center 26px;
    width: 143px;
    height: 193px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 214px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-143px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 143px;
    background-position: center 5px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-143px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 34px;
    background-image: url("/media/home/team/mike-143px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 214px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-143px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 53px;
    left: 130px;
    font-size: 24px;
    line-height: 22px;
    width: 68px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 212px;
    font-size: 16px;
    line-height: 21px;
    width: 209px;
  }
}

@media only screen and (min-width: 1536px) {
  .MeetOurTeamMember {
    width: 222px;
    height: 326px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 222px;
    height: 326px;
  }

  .MeetOurTeamMember {
    margin-top: -10px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 122px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 122px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 10px;
    background-size: 153px 152px;
    width: 153px;
    height: 206px;
  }

  .MeetOurTeamMember img {
    background-size: 153px;
    background-position: center 27px;
    width: 153px;
    height: 206px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 228px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-153px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 153px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-153px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 36px;
    background-image: url("/media/home/team/mike-153px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 228px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-153px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 57px;
    left: 138px;
    font-size: 26px;
    line-height: 23px;
    width: 73px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 226px;
    font-size: 17px;
    line-height: 22px;
    width: 223px;
  }
}

@media only screen and (min-width: 1600px) {
  .MeetOurTeamMember {
    width: 232px;
    height: 340px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 232px;
    height: 340px;
  }

  .MeetOurTeamMember {
    margin-top: -10px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 127px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 127px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 11px;
    background-size: 159px 158px;
    width: 159px;
    height: 214px;
  }

  .MeetOurTeamMember img {
    background-size: 159px;
    background-position: center 28px;
    width: 159px;
    height: 214px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 238px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-159px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 159px;
    background-position: center 6px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-159px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 38px;
    background-image: url("/media/home/team/mike-159px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 238px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-159px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 59px;
    left: 144px;
    font-size: 27px;
    line-height: 24px;
    width: 76px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 235px;
    font-size: 18px;
    line-height: 23px;
    width: 232px;
  }
}

@media only screen and (min-width: 1800px) {
  .MeetOurTeamMember {
    width: 261px;
    height: 382px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 261px;
    height: 382px;
  }

  .MeetOurTeamMember {
    margin-top: -11px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 142px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 142px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 12px;
    background-size: 179px 178px;
    width: 179px;
    height: 241px;
  }

  .MeetOurTeamMember img {
    background-size: 179px;
    background-position: center 32px;
    width: 179px;
    height: 241px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 267px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-179px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 179px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-179px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 42px;
    background-image: url("/media/home/team/mike-179px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 267px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-179px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 67px;
    left: 162px;
    font-size: 30px;
    line-height: 27px;
    width: 85px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 264px;
    font-size: 20px;
    line-height: 26px;
    width: 262px;
  }
}

@media only screen and (min-width: 1920px) {
  .MeetOurTeamMember {
    width: 278px;
    height: 408px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 278px;
    height: 408px;
  }

  .MeetOurTeamMember {
    margin-top: -12px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 152px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 152px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 13px;
    background-size: 191px 190px;
    width: 191px;
    height: 257px;
  }

  .MeetOurTeamMember img {
    background-size: 191px;
    background-position: center 34px;
    width: 191px;
    height: 257px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 285px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-191px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 191px;
    background-position: center 7px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-191px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 45px;
    background-image: url("/media/home/team/mike-191px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 285px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-191px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 71px;
    left: 173px;
    font-size: 32px;
    line-height: 29px;
    width: 91px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 282px;
    font-size: 21px;
    line-height: 28px;
    width: 279px;
  }
}

@media only screen and (min-width: 2120px) {
  .MeetOurTeamMember {
    width: 307px;
    height: 451px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 307px;
    height: 451px;
  }

  .MeetOurTeamMember {
    margin-top: -13px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 168px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 168px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 14px;
    background-size: 211px 210px;
    width: 211px;
    height: 284px;
  }

  .MeetOurTeamMember img {
    background-size: 211px;
    background-position: center 38px;
    width: 211px;
    height: 284px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 315px;
    background-position: center 9px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-211px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 211px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-211px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 50px;
    background-image: url("/media/home/team/mike-211px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 315px;
    background-position: center 9px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-211px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 78px;
    left: 191px;
    font-size: 35px;
    line-height: 32px;
    width: 100px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 311px;
    font-size: 23px;
    line-height: 31px;
    width: 308px;
  }
}

@media only screen and (min-width: 2320px) {
  .MeetOurTeamMember {
    width: 336px;
    height: 493px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 336px;
    height: 493px;
  }

  .MeetOurTeamMember {
    margin-top: -14px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 184px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 184px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 16px;
    background-size: 231px 230px;
    width: 231px;
    height: 311px;
  }

  .MeetOurTeamMember img {
    background-size: 231px;
    background-position: center 41px;
    width: 231px;
    height: 311px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 344px;
    background-position: center 10px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-231px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 231px;
    background-position: center 8px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-231px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 54px;
    background-image: url("/media/home/team/mike-231px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 344px;
    background-position: center 10px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-231px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 86px;
    left: 209px;
    font-size: 39px;
    line-height: 35px;
    width: 110px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 341px;
    font-size: 25px;
    line-height: 34px;
    width: 337px;
  }
}

@media only screen and (min-width: 2520px) {
  .MeetOurTeamMember {
    width: 365px;
    height: 536px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 365px;
    height: 536px;
  }

  .MeetOurTeamMember {
    margin-top: -16px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 200px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 200px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 17px;
    background-size: 251px 249px;
    width: 251px;
    height: 337px;
  }

  .MeetOurTeamMember img {
    background-size: 251px;
    background-position: center 45px;
    width: 251px;
    height: 337px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 374px;
    background-position: center 10px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-251px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 251px;
    background-position: center 9px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-251px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 59px;
    background-image: url("/media/home/team/mike-251px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 374px;
    background-position: center 10px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-251px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 93px;
    left: 227px;
    font-size: 42px;
    line-height: 38px;
    width: 119px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 370px;
    font-size: 28px;
    line-height: 37px;
    width: 366px;
  }
}

@media only screen and (min-width: 2720px) {
  .MeetOurTeamMember {
    width: 394px;
    height: 578px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 394px;
    height: 578px;
  }

  .MeetOurTeamMember {
    margin-top: -17px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 215px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 215px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 18px;
    background-size: 271px 269px;
    width: 271px;
    height: 364px;
  }

  .MeetOurTeamMember img {
    background-size: 271px;
    background-position: center 48px;
    width: 271px;
    height: 364px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 404px;
    background-position: center 11px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-271px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 271px;
    background-position: center 10px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 1px;
    background-image: url("/media/home/team/lea-271px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 1px;
    background-position: center 64px;
    background-image: url("/media/home/team/mike-271px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 404px;
    background-position: center 11px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 1px;
    background-image: url("/media/home/team/julia-271px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 101px;
    left: 245px;
    font-size: 45px;
    line-height: 41px;
    width: 129px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 400px;
    font-size: 30px;
    line-height: 40px;
    width: 395px;
  }
}

@media only screen and (min-width: 2920px) {
  .MeetOurTeamMember {
    width: 423px;
    height: 620px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 423px;
    height: 620px;
  }

  .MeetOurTeamMember {
    margin-top: -18px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 231px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 231px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 20px;
    background-size: 290px 289px;
    width: 290px;
    height: 391px;
  }

  .MeetOurTeamMember img {
    background-size: 290px;
    background-position: center 52px;
    width: 290px;
    height: 391px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 433px;
    background-position: center 12px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-290px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 290px;
    background-position: center 11px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 2px;
    background-image: url("/media/home/team/lea-290px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 2px;
    background-position: center 68px;
    background-image: url("/media/home/team/mike-290px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 433px;
    background-position: center 12px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 2px;
    background-image: url("/media/home/team/julia-290px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 108px;
    left: 263px;
    font-size: 49px;
    line-height: 44px;
    width: 138px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 429px;
    font-size: 32px;
    line-height: 43px;
    width: 424px;
  }
}

@media only screen and (min-width: 3120px) {
  .MeetOurTeamMember {
    width: 452px;
    height: 663px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 452px;
    height: 663px;
  }

  .MeetOurTeamMember {
    margin-top: -20px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 247px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 247px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 21px;
    background-size: 310px 309px;
    width: 310px;
    height: 418px;
  }

  .MeetOurTeamMember img {
    background-size: 310px;
    background-position: center 55px;
    width: 310px;
    height: 418px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 463px;
    background-position: center 13px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-310px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 310px;
    background-position: center 11px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 2px;
    background-image: url("/media/home/team/lea-310px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 2px;
    background-position: center 73px;
    background-image: url("/media/home/team/mike-310px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 463px;
    background-position: center 13px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 2px;
    background-image: url("/media/home/team/julia-310px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 115px;
    left: 281px;
    font-size: 52px;
    line-height: 47px;
    width: 148px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 458px;
    font-size: 34px;
    line-height: 46px;
    width: 453px;
  }
}

@media only screen and (min-width: 3320px) {
  .MeetOurTeamMember {
    width: 481px;
    height: 706px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 481px;
    height: 706px;
  }

  .MeetOurTeamMember {
    margin-top: -21px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 263px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 263px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 22px;
    background-size: 330px 329px;
    width: 330px;
    height: 444px;
  }

  .MeetOurTeamMember img {
    background-size: 330px;
    background-position: center 59px;
    width: 330px;
    height: 444px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 493px;
    background-position: center 14px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-330px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 330px;
    background-position: center 12px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 2px;
    background-image: url("/media/home/team/lea-330px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 2px;
    background-position: center 78px;
    background-image: url("/media/home/team/mike-330px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 493px;
    background-position: center 14px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 2px;
    background-image: url("/media/home/team/julia-330px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 123px;
    left: 299px;
    font-size: 55px;
    line-height: 50px;
    width: 157px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 488px;
    font-size: 36px;
    line-height: 48px;
    width: 482px;
  }
}

@media only screen and (min-width: 3520px) {
  .MeetOurTeamMember {
    width: 510px;
    height: 748px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 510px;
    height: 748px;
  }

  .MeetOurTeamMember {
    margin-top: -22px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 279px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 279px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 24px;
    background-size: 350px 348px;
    width: 350px;
    height: 471px;
  }

  .MeetOurTeamMember img {
    background-size: 350px;
    background-position: center 62px;
    width: 350px;
    height: 471px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 522px;
    background-position: center 15px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-350px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 350px;
    background-position: center 13px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 2px;
    background-image: url("/media/home/team/lea-350px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 2px;
    background-position: center 82px;
    background-image: url("/media/home/team/mike-350px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 522px;
    background-position: center 15px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 2px;
    background-image: url("/media/home/team/julia-350px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 130px;
    left: 317px;
    font-size: 59px;
    line-height: 53px;
    width: 167px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 517px;
    font-size: 38px;
    line-height: 51px;
    width: 512px;
  }
}

@media only screen and (min-width: 3720px) {
  .MeetOurTeamMember {
    width: 539px;
    height: 790px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 539px;
    height: 790px;
  }

  .MeetOurTeamMember {
    margin-top: -23px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 294px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 294px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 25px;
    background-size: 370px 368px;
    width: 370px;
    height: 498px;
  }

  .MeetOurTeamMember img {
    background-size: 370px;
    background-position: center 66px;
    width: 370px;
    height: 498px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 552px;
    background-position: center 16px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-370px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 370px;
    background-position: center 14px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 2px;
    background-image: url("/media/home/team/lea-370px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 2px;
    background-position: center 87px;
    background-image: url("/media/home/team/mike-370px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 552px;
    background-position: center 16px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 2px;
    background-image: url("/media/home/team/julia-370px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 138px;
    left: 335px;
    font-size: 62px;
    line-height: 56px;
    width: 176px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 546px;
    font-size: 41px;
    line-height: 54px;
    width: 541px;
  }
}

@media only screen and (min-width: 3920px) {
  .MeetOurTeamMember {
    width: 568px;
    height: 833px;
  }

  .MeetOurTeamMember > .motm-container {
    width: 568px;
    height: 833px;
  }

  .MeetOurTeamMember {
    margin-top: -24px;
  }

  .MeetOurTeamMember.motm-pam-progg {
    margin-top: 310px;
  }

  .MeetOurTeamMember.motm-rico-coder {
    margin-top: 310px;
  }

  .MeetOurTeamMember > .motm-container > .motm-member {
    left: 27px;
    background-size: 390px 388px;
    width: 390px;
    height: 525px;
  }

  .MeetOurTeamMember img {
    background-size: 390px;
    background-position: center 69px;
    width: 390px;
    height: 525px;
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-pam-progg img {
    background-image: url("/media/home/team/pam-progg.png");
    background-size: 582px;
    background-position: center 16px;
  }

  .MeetOurTeamMember.motm-marc img {
    background-image: url("/media/home/team/marc-390px.png");
  }

  .MeetOurTeamMember.motm-anton-robot img {
    background-image: url("/media/home/team/anton-robot.png");
    background-size: 390px;
    background-position: center 14px;
  }

  .MeetOurTeamMember.motm-lea img {
    top: 2px;
    background-image: url("/media/home/team/lea-390px.png");
    clip-path: ellipse(57% 50% at 50% 50%);
  }

  .MeetOurTeamMember.motm-mike img {
    top: 2px;
    background-position: center 92px;
    background-image: url("/media/home/team/mike-390px.png");
  }

  .MeetOurTeamMember.motm-rico-coder img {
    top: 0px;
    background-image: url("/media/home/team/rico-coder.png");
    background-size: 582px;
    background-position: center 16px;
  }

  .MeetOurTeamMember.motm-julia img {
    top: 2px;
    background-image: url("/media/home/team/julia-390px.png");
  }

  .MeetOurTeamMember > .motm-container > h4 {
    top: 145px;
    left: 353px;
    font-size: 65px;
    line-height: 59px;
    width: 186px;
  }

  .MeetOurTeamMember > .motm-container > p {
    top: 576px;
    font-size: 43px;
    line-height: 57px;
    width: 570px;
  }
}
