@import "../../variables.scss";

#AboutUsVideo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px;
  width: 100%;
}

#AboutUsVideo > .au-container > .au-bubble {
  position: absolute;
  left: -210px;
}

#AboutUsVideo > .au-container > .au-video-container > .au-video {
  width: 254px;
  height: 143px;
  background-color: $coloref2996;
  border-radius: 11px;
}

@media only screen and (min-width: 320px) {
  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 291px;
    height: 164px;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 375px) {
  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 341px;
    height: 192px;
    border-radius: 14px;
  }
}

@media only screen and (min-width: 414px) {
  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 376px;
    height: 212px;
    border-radius: 16px;
  }
}

@media only screen and (min-width: 500px) {
  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 454px;
    height: 256px;
    border-radius: 19px;
  }
}

@media only screen and (min-width: 600px) {
  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 545px;
    height: 307px;
    border-radius: 23px;
  }
}

@media only screen and (min-width: 768px) {
  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 698px;
    height: 393px;
    border-radius: 29px;
  }
}

@media only screen and (min-width: 992px) {
  #AboutUsVideo {
    justify-content: flex-start;
    margin-top: 130px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    position: relative;
    left: 0px;
    width: 100%;
    height: 113px;
    background-image: url("data:image/svg+xml;charset=US-ASCII, %3Csvg xmlns='http://www.w3.org/2000/svg' width='417.811' height='116.871'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%237d1cc9%7D%3C/style%3E%3C/defs%3E%3Crect class='a' width='417.811' height='87.364' rx='25'/%3E%3Cpath class='a' d='M49.782 43.403H0v65.821a7.632 7.632 0 0013.029 5.4l71.7-71.7z'/%3E%3C/svg%3E");
    background-position: top 0px left 105px;
    background-size: 405px 113px;
    background-repeat: no-repeat;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    display: inline-block;
    margin-top: 12px;
    margin-left: 166px;
    width: 358px;
    height: 62px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 23px;
    line-height: 30px;
    width: 298px;
    color: #ffffff;
    text-align: center;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p > strong {
    color: $colorffea6c;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 936px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 702px;
    height: 395px;
    background-color: $coloref2996;
    border-radius: 38px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 233px;
    height: 395px;
    background: url("/media/home/rico-coder-c-476.png");
    background-position: top -16px left -34px;
    background-size: 231px 288px;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1024px) {
  #AboutUsVideo {
    margin-top: 134px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    height: 117px;
    background-position: top 0px left 108px;
    background-size: 418px 117px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 12px;
    margin-left: 171px;
    width: 370px;
    height: 64px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 24px;
    line-height: 31px;
    width: 307px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 966px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 725px;
    height: 408px;
    border-radius: 39px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 241px;
    height: 408px;
    background-position: top -17px left -35px;
    background-size: 238px 297px;
  }
}

@media only screen and (min-width: 1200px) {
  #AboutUsVideo {
    margin-top: 157px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    height: 137px;
    background-position: top 0px left 127px;
    background-size: 490px 137px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 14px;
    margin-left: 200px;
    width: 434px;
    height: 75px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 28px;
    line-height: 36px;
    width: 359px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1132px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 850px;
    height: 478px;
    border-radius: 46px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 282px;
    height: 478px;
    background-position: top -20px left -41px;
    background-size: 279px 348px;
  }
}

@media only screen and (min-width: 1366px) {
  #AboutUsVideo {
    margin-top: 127px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1123px;
    height: 106px;
    background-image: url("data:image/svg+xml;charset=US-ASCII, %3Csvg xmlns='http://www.w3.org/2000/svg' width='532.366' height='148.915'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%237d1cc9%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-500.431 -1704.151)'%3E%3Crect class='a' width='532.366' height='111.317' rx='35' transform='translate(500.431 1704.151)'/%3E%3Cpath class='a' d='M563.862 1759.454h-63.431v83.867a9.725 9.725 0 0016.6 6.877l91.354-91.354z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 378px 106px;
    background-position: left 354px top 0px;
    background-repeat: no-repeat;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 11px;
    margin-left: 411px;
    width: 270px;
    height: 56px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 23px;
    line-height: 28px;
    width: 270px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1123px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 660px;
    height: 371px;
    border-radius: 28px;
    margin-top: 36px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 464px;
    height: 406px;
    background: url("/media/home/rico-coder-c-476.png");
    background-position: left 196px top -35px;
    background-size: 231px 288px;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1440px) {
  #AboutUsVideo {
    margin-top: 134px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1184px;
    height: 112px;
    background-size: 399px 112px;
    background-position: left 374px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 11px;
    margin-left: 434px;
    width: 285px;
    height: 59px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 24px;
    line-height: 29px;
    width: 285px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1184px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 695px;
    height: 391px;
    border-radius: 29px;
    margin-top: 38px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 489px;
    height: 428px;
    background-position: left 207px top -37px;
    background-size: 243px 304px;
  }
}

@media only screen and (min-width: 1536px) {
  #AboutUsVideo {
    margin-top: 142px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1263px;
    height: 119px;
    background-size: 426px 119px;
    background-position: left 398px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 12px;
    margin-left: 462px;
    width: 304px;
    height: 63px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 26px;
    line-height: 31px;
    width: 304px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1263px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 742px;
    height: 417px;
    border-radius: 31px;
    margin-top: 40px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 522px;
    height: 457px;
    background-position: left 221px top -39px;
    background-size: 259px 324px;
  }
}

@media only screen and (min-width: 1600px) {
  #AboutUsVideo {
    margin-top: 148px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1316px;
    height: 124px;
    background-size: 443px 124px;
    background-position: left 415px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 12px;
    margin-left: 482px;
    width: 317px;
    height: 66px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 27px;
    line-height: 32px;
    width: 317px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1316px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 772px;
    height: 434px;
    border-radius: 32px;
    margin-top: 42px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 543px;
    height: 476px;
    background-position: left 230px top -41px;
    background-size: 270px 338px;
  }
}

@media only screen and (min-width: 1800px) {
  #AboutUsVideo {
    margin-top: 167px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1480px;
    height: 140px;
    background-size: 499px 140px;
    background-position: left 467px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 14px;
    margin-left: 542px;
    width: 356px;
    height: 74px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 30px;
    line-height: 37px;
    width: 356px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1480px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 869px;
    height: 488px;
    border-radius: 37px;
    margin-top: 47px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 611px;
    height: 535px;
    background-position: left 259px top -46px;
    background-size: 304px 380px;
  }
}

@media only screen and (min-width: 1920px) {
  #AboutUsVideo {
    margin-top: 178px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1579px;
    height: 149px;
    background-size: 532px 149px;
    background-position: left 498px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 15px;
    margin-left: 578px;
    width: 380px;
    height: 79px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 32px;
    line-height: 39px;
    width: 380px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1579px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 927px;
    height: 521px;
    border-radius: 39px;
    margin-top: 50px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 652px;
    height: 571px;
    background-position: left 276px top -49px;
    background-size: 324px 405px;
  }
}

@media only screen and (min-width: 2120px) {
  #AboutUsVideo {
    margin-top: 197px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1743px;
    height: 165px;
    background-size: 587px 165px;
    background-position: left 550px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 17px;
    margin-left: 638px;
    width: 420px;
    height: 87px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 35px;
    line-height: 43px;
    width: 420px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1743px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1024px;
    height: 575px;
    border-radius: 43px;
    margin-top: 55px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 720px;
    height: 630px;
    background-position: left 305px top -54px;
    background-size: 358px 447px;
  }
}

@media only screen and (min-width: 2320px) {
  #AboutUsVideo {
    margin-top: 215px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 1908px;
    height: 180px;
    background-size: 643px 180px;
    background-position: left 602px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 18px;
    margin-left: 698px;
    width: 459px;
    height: 95px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 39px;
    line-height: 47px;
    width: 459px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 1908px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1120px;
    height: 630px;
    border-radius: 47px;
    margin-top: 60px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 788px;
    height: 690px;
    background-position: left 334px top -59px;
    background-size: 392px 489px;
  }
}

@media only screen and (min-width: 2520px) {
  #AboutUsVideo {
    margin-top: 234px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 2072px;
    height: 196px;
    background-size: 698px 196px;
    background-position: left 654px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 20px;
    margin-left: 759px;
    width: 499px;
    height: 104px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 42px;
    line-height: 51px;
    width: 499px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 2072px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1217px;
    height: 684px;
    border-radius: 51px;
    margin-top: 66px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 856px;
    height: 749px;
    background-position: left 362px top -64px;
    background-size: 425px 532px;
  }
}

@media only screen and (min-width: 2720px) {
  #AboutUsVideo {
    margin-top: 252px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 2237px;
    height: 211px;
    background-size: 754px 211px;
    background-position: left 706px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 21px;
    margin-left: 819px;
    width: 538px;
    height: 112px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 45px;
    line-height: 55px;
    width: 538px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 2237px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1313px;
    height: 738px;
    border-radius: 55px;
    margin-top: 71px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 924px;
    height: 809px;
    background-position: left 391px top -69px;
    background-size: 459px 574px;
  }
}

@media only screen and (min-width: 2920px) {
  #AboutUsVideo {
    margin-top: 271px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 2401px;
    height: 227px;
    background-size: 809px 227px;
    background-position: left 757px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 23px;
    margin-left: 879px;
    width: 578px;
    height: 120px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 49px;
    line-height: 59px;
    width: 578px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 2401px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1410px;
    height: 792px;
    border-radius: 59px;
    margin-top: 76px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 992px;
    height: 868px;
    background-position: left 420px top -75px;
    background-size: 493px 616px;
  }
}

@media only screen and (min-width: 3120px) {
  #AboutUsVideo {
    margin-top: 289px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 2566px;
    height: 242px;
    background-size: 864px 242px;
    background-position: left 809px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 24px;
    margin-left: 939px;
    width: 618px;
    height: 128px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 52px;
    line-height: 63px;
    width: 618px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 2566px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1506px;
    height: 847px;
    border-radius: 63px;
    margin-top: 81px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 1060px;
    height: 928px;
    background-position: left 448px top -80px;
    background-size: 526px 658px;
  }
}

@media only screen and (min-width: 3320px) {
  #AboutUsVideo {
    margin-top: 308px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 2730px;
    height: 258px;
    background-size: 920px 258px;
    background-position: left 861px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 26px;
    margin-left: 999px;
    width: 657px;
    height: 137px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 55px;
    line-height: 67px;
    width: 657px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 2730px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1603px;
    height: 901px;
    border-radius: 67px;
    margin-top: 86px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 1127px;
    height: 987px;
    background-position: left 477px top -85px;
    background-size: 560px 700px;
  }
}

@media only screen and (min-width: 3520px) {
  #AboutUsVideo {
    margin-top: 326px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 2895px;
    height: 273px;
    background-size: 975px 273px;
    background-position: left 913px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 28px;
    margin-left: 1060px;
    width: 697px;
    height: 145px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 59px;
    line-height: 72px;
    width: 697px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 2895px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1700px;
    height: 955px;
    border-radius: 72px;
    margin-top: 92px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 1195px;
    height: 1047px;
    background-position: left 506px top -90px;
    background-size: 594px 742px;
  }
}

@media only screen and (min-width: 3720px) {
  #AboutUsVideo {
    margin-top: 345px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 3059px;
    height: 289px;
    background-size: 1031px 289px;
    background-position: left 965px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 29px;
    margin-left: 1120px;
    width: 736px;
    height: 153px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 62px;
    line-height: 76px;
    width: 736px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 3059px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1796px;
    height: 1009px;
    border-radius: 76px;
    margin-top: 97px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 1263px;
    height: 1106px;
    background-position: left 535px top -95px;
    background-size: 628px 785px;
  }
}

@media only screen and (min-width: 3920px) {
  #AboutUsVideo {
    margin-top: 363px;
  }

  #AboutUsVideo > .au-container > .au-bubble {
    width: 3224px;
    height: 304px;
    background-size: 1086px 304px;
    background-position: left 1017px top 0px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content {
    margin-top: 31px;
    margin-left: 1180px;
    width: 776px;
    height: 161px;
  }

  #AboutUsVideo > .au-container > .au-bubble > .au-bubble-content > p {
    font-size: 65px;
    line-height: 80px;
    width: 776px;
  }

  #AboutUsVideo > .au-container > .au-video-container {
    width: 3224px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-video {
    width: 1893px;
    height: 1064px;
    border-radius: 80px;
    margin-top: 102px;
  }

  #AboutUsVideo > .au-container > .au-video-container > .au-rico {
    width: 1331px;
    height: 1166px;
    background-position: left 564px top -100px;
    background-size: 662px 827px;
  }
}
