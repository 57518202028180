@import "variables.scss";

#App {
  width: 100%;
  min-width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#App > .app-container {
  width: 100%;
  max-width: 3920px;
}

@media only screen and (min-width: 3920px) {
  #App {
    background-color: $color280c4c;
  }

  #App > .app-container {
    background-color: $colorf5feff;
    width: 3918px;
  }
}
